export const czech: { [codePoint: string]: string } = {
    "U+1F600": "Šklebící se obličej",
    "U+1F601": "Šklebící se obličej s usměvavýma očima",
    "U+1F602": "Tvář se slzami radosti",
    "U+1F923": "Válel se po podlaze smíchy",
    "U+1F603": "Usmívající se tvář s otevřenými ústy",
    "U+1F604": "Usmívající se obličej s otevřenými ústy a usmívající se očima ≊ usmívající se obličej s otevřenými ústy",
    "U+1F605": "Usměvavý obličej s otevřenými ústy a studený pot ≊ usměvavý obličej s otevřenými ústy",
    "U+1F606": "Usmívající se obličej s otevřenými ústy a pevně zavřenýma očima ≊ usmívající se obličej s otevřenými ústy",
    "U+1F609": "Mrkací tvář",
    "U+1F60A": "Usměvavá tvář s usměvavýma očima",
    "U+1F60B": "Tvář vychutnávající lahodné jídlo",
    "U+1F60E": "Usmívající se tvář se slunečními brýlemi",
    "U+1F60D": "Usmívající se obličej s očima ve tvaru srdce ≊ usmívající se obličej s očima ve tvaru srdce",
    "U+1F618": "Tvář házet polibek",
    "U+1F617": "Líbající tvář",
    "U+1F619": "Líbající tvář s usměvavýma očima",
    "U+1F61A": "Líbající tvář se zavřenýma očima",
    "U+263A": "Bílá usměvavá tvář ≊ usměvavá tvář",
    "U+1F642": "Mírně usměvavý obličej",
    "U+1F917": "Objímání obličeje",
    "U+1F914": "Myslící tvář",
    "U+1F610": "Neutrální tvář",
    "U+1F611": "Bezvýrazná tvář",
    "U+1F636": "Obličej bez úst",
    "U+1F644": "Obličej s vykulenýma očima",
    "U+1F60F": "Usměvavý obličej",
    "U+1F623": "Vytrvalá tvář",
    "U+1F625": "Zklamaný, ale úlevný obličej",
    "U+1F62E": "Tvář s otevřenými ústy",
    "U+1F910": "Zip-ústa obličej",
    "U+1F62F": "Ztichlý obličej",
    "U+1F62A": "Ospalý obličej",
    "U+1F62B": "Unavený obličej",
    "U+1F634": "Spící tvář",
    "U+1F60C": "Úlevný obličej",
    "U+1F913": "Blbeček obličej",
    "U+1F61B": "Obličej s vyplazeným jazykem",
    "U+1F61C": "Obličej s vyplazeným jazykem a mrkajícím okem ≊ obličej s vyplazeným jazykem",
    "U+1F61D": "Obličej s vyplazeným jazykem a pevně zavřenýma očima ≊ obličej s vyplazeným jazykem",
    "U+1F924": "Slintající obličej",
    "U+1F612": "Nepobavený obličej",
    "U+1F613": "Tvář se studeným potem",
    "U+1F614": "Zamyšlený obličej",
    "U+1F615": "Zmatený obličej",
    "U+1F643": "Vzhůru nohama obličej",
    "U+1F911": "Peníze-ústa tvář",
    "U+1F632": "Udivený obličej",
    "U+2639": "Bílý zamračený obličej ≊ zamračený obličej",
    "U+1F641": "Mírně zamračený obličej",
    "U+1F616": "Zmatený obličej",
    "U+1F61E": "Zklamaný obličej",
    "U+1F61F": "Ustaraný obličej",
    "U+1F624": "Tvář s triumfálním výrazem ≊ tvář s párou z nosu",
    "U+1F622": "Plačící tvář",
    "U+1F62D": "Hlasitě plačící tvář",
    "U+1F626": "Zamračený obličej s otevřenými ústy",
    "U+1F627": "Utrápená tvář",
    "U+1F628": "Ustrašená tvář",
    "U+1F629": "Unavený obličej",
    "U+1F62C": "Šklebící se obličej",
    "U+1F630": "Obličej s otevřenými ústy a studený pot ≊ obličej s otevřenými ústy",
    "U+1F631": "Tvář křičící strachem",
    "U+1F633": "Zarudlý obličej",
    "U+1F635": "Závrať obličej",
    "U+1F621": "Našpulený obličej",
    "U+1F620": "Naštvaný obličej",
    "U+1F607": "Usmívající se tvář s halo",
    "U+1F920": "Obličej s kovbojským kloboukem ≊ obličej s kovbojským kloboukem",
    "U+1F921": "Tvář klauna",
    "U+1F925": "Ležící tvář",
    "U+1F637": "Obličej s lékařskou maskou",
    "U+1F912": "Obličej s teploměrem",
    "U+1F915": "Obličej s obvazem hlavy",
    "U+1F922": "Nevolný obličej",
    "U+1F927": "Kýchající obličej",
    "U+1F608": "Usmívající se tvář s rohy",
    "U+1F47F": "Imp",
    "U+1F479": "Japonský zlobr ≊ zlobr",
    "U+1F47A": "Japonský goblin ≊ goblin",
    "U+1F480": "Lebka",
    "U+2620": "Lebka a zkřížené hnáty",
    "U+1F47B": "Duch",
    "U+1F47D": "Mimozemský mimozemšťan ≊ mimozemšťan",
    "U+1F47E": "Mimozemské monstrum",
    "U+1F916": "Robot tvář",
    "U+1F4A9": "Hromada hovínka",
    "U+1F63A": "Usmívající se kočičí tvář s otevřenými ústy",
    "U+1F638": "Šklebící se kočičí tvář s usměvavýma očima",
    "U+1F639": "Kočičí tvář se slzami radosti",
    "U+1F63B": "Usměvavá kočičí tvář s očima ve tvaru srdce ≊ usměvavá kočičí tvář s očima ve tvaru srdce",
    "U+1F63C": "Kočičí tvář s ironickým úsměvem",
    "U+1F63D": "Líbání kočičí tvář se zavřenýma očima",
    "U+1F640": "Unavený kočičí obličej",
    "U+1F63F": "Plačící kočičí tvář",
    "U+1F63E": "Našpulená kočičí tvář",
    "U+1F648": "Nevidomá opice ≊ nevidět zlo",
    "U+1F649": "Neslyšící opice ≊ neslyšící zlo",
    "U+1F64A": "Mluvit-no-zlo opice ≊ mluvit-ne-zlo",
    "U+1F466": "Chlapec",
    "U+1F466 U+1F3FB": "Chlapec, typ-1-2",
    "U+1F466 U+1F3FC": "Chlapec, typ 3",
    "U+1F466 U+1F3FD": "Chlapec, typ 4",
    "U+1F466 U+1F3FE": "Chlapec, typ-5",
    "U+1F466 U+1F3FF": "Chlapec, typ 6",
    "U+1F467": "Dívka",
    "U+1F467 U+1F3FB": "Dívka, typ-1-2",
    "U+1F467 U+1F3FC": "Dívka, typ 3",
    "U+1F467 U+1F3FD": "Dívka, typ 4",
    "U+1F467 U+1F3FE": "Dívka, typ-5",
    "U+1F467 U+1F3FF": "Dívka, typ 6",
    "U+1F468": "Muž",
    "U+1F468 U+1F3FB": "Muž, typ-1-2",
    "U+1F468 U+1F3FC": "Muž, typ 3",
    "U+1F468 U+1F3FD": "Muž, typ 4",
    "U+1F468 U+1F3FE": "Muž, typ-5",
    "U+1F468 U+1F3FF": "Muž, typ 6",
    "U+1F469": "Žena",
    "U+1F469 U+1F3FB": "Žena, typ 1-2",
    "U+1F469 U+1F3FC": "Žena, typ 3",
    "U+1F469 U+1F3FD": "Žena, typ 4",
    "U+1F469 U+1F3FE": "Žena, typ 5",
    "U+1F469 U+1F3FF": "Žena, typ 6",
    "U+1F474": "Starší muž ≊ starý muž",
    "U+1F474 U+1F3FB": "Starý muž, typ-1-2",
    "U+1F474 U+1F3FC": "Starý muž, typ 3",
    "U+1F474 U+1F3FD": "Starý muž, typ 4",
    "U+1F474 U+1F3FE": "Starý muž, typ-5",
    "U+1F474 U+1F3FF": "Starý muž, typ-6",
    "U+1F475": "Starší žena ≊ stará žena",
    "U+1F475 U+1F3FB": "Stará žena, typ 1-2",
    "U+1F475 U+1F3FC": "Stará žena, typ 3",
    "U+1F475 U+1F3FD": "Stará žena, typ 4",
    "U+1F475 U+1F3FE": "Stará žena, typ-5",
    "U+1F475 U+1F3FF": "Stará žena, typ 6",
    "U+1F476": "Dítě",
    "U+1F476 U+1F3FB": "Dítě, typ-1-2",
    "U+1F476 U+1F3FC": "Dítě, typ 3",
    "U+1F476 U+1F3FD": "Dítě, typ 4",
    "U+1F476 U+1F3FE": "Dítě, typ-5",
    "U+1F476 U+1F3FF": "Dítě, typ 6",
    "U+1F47C": "Andělíček",
    "U+1F47C U+1F3FB": "Andělíček, typ 1-2",
    "U+1F47C U+1F3FC": "Andělíček, typ 3",
    "U+1F47C U+1F3FD": "Andělíček, typ 4",
    "U+1F47C U+1F3FE": "Andělíček, typ 5",
    "U+1F47C U+1F3FF": "Andělíček, typ 6",
    "U+1F46E": "Policejní důstojník",
    "U+1F46E U+1F3FB": "Policista, typ-1-2",
    "U+1F46E U+1F3FC": "Policista, typ 3",
    "U+1F46E U+1F3FD": "Policista, typ 4",
    "U+1F46E U+1F3FE": "Policista, typ-5",
    "U+1F46E U+1F3FF": "Policista, typ 6",
    "U+1F575": "Detektiv nebo špión ≊ detektiv",
    "U+1F575 U+1F3FB": "Detektiv, typ-1-2",
    "U+1F575 U+1F3FC": "Detektiv, typ 3",
    "U+1F575 U+1F3FD": "Detektiv, typ 4",
    "U+1F575 U+1F3FE": "Detektiv, typ-5",
    "U+1F575 U+1F3FF": "Detektiv, typ 6",
    "U+1F482": "Gardista",
    "U+1F482 U+1F3FB": "Strážmistr, typ-1-2",
    "U+1F482 U+1F3FC": "Strážmistr, typ 3",
    "U+1F482 U+1F3FD": "Strážmistr, typ 4",
    "U+1F482 U+1F3FE": "Strážmistr, typ 5",
    "U+1F482 U+1F3FF": "Strážmistr, typ 6",
    "U+1F477": "Stavební dělník",
    "U+1F477 U+1F3FB": "Stavební dělník, typ-1-2",
    "U+1F477 U+1F3FC": "Stavební dělník, typ-3",
    "U+1F477 U+1F3FD": "Stavební dělník, typ-4",
    "U+1F477 U+1F3FE": "Stavební dělník, typ-5",
    "U+1F477 U+1F3FF": "Stavební dělník, typ-6",
    "U+1F473": "Muž s turbanem ≊ člověk s turbanem",
    "U+1F473 U+1F3FB": "Osoba s turbanem, typ-1-2",
    "U+1F473 U+1F3FC": "Osoba s turbanem, typ 3",
    "U+1F473 U+1F3FD": "Osoba s turbanem, typ-4",
    "U+1F473 U+1F3FE": "Osoba s turbanem, typ-5",
    "U+1F473 U+1F3FF": "Osoba s turbanem, typ-6",
    "U+1F471": "Osoba s blond vlasy ≊ blond osoba",
    "U+1F471 U+1F3FB": "Blonďatá osoba, typ 1-2",
    "U+1F471 U+1F3FC": "Blonďatá osoba, typ 3",
    "U+1F471 U+1F3FD": "Blonďatá osoba, typ 4",
    "U+1F471 U+1F3FE": "Blonďatá osoba, typ 5",
    "U+1F471 U+1F3FF": "Blonďatá osoba, typ 6",
    "U+1F385": "Otec vánoce ≊ Santa Claus",
    "U+1F385 U+1F3FB": "Santa Claus, typ-1-2",
    "U+1F385 U+1F3FC": "Santa Claus, typ 3",
    "U+1F385 U+1F3FD": "Santa Claus, typ 4",
    "U+1F385 U+1F3FE": "Santa Claus, typ 5",
    "U+1F385 U+1F3FF": "Santa Claus, typ 6",
    "U+1F936": "Matka vánoce",
    "U+1F936 U+1F3FB": "Matka vánoce, typ-1-2",
    "U+1F936 U+1F3FC": "Matka vánoce, typ 3",
    "U+1F936 U+1F3FD": "Matka vánoce, typ 4",
    "U+1F936 U+1F3FE": "Matka vánoce, typ 5",
    "U+1F936 U+1F3FF": "Matka vánoce, typ 6",
    "U+1F478": "Princezna",
    "U+1F478 U+1F3FB": "Princezna, typ-1-2",
    "U+1F478 U+1F3FC": "Princezna, typ 3",
    "U+1F478 U+1F3FD": "Princezna, typ 4",
    "U+1F478 U+1F3FE": "Princezna, typ-5",
    "U+1F478 U+1F3FF": "Princezna, typ-6",
    "U+1F934": "Princ",
    "U+1F934 U+1F3FB": "Princ, typ-1-2",
    "U+1F934 U+1F3FC": "Princ, typ-3",
    "U+1F934 U+1F3FD": "Princ, typ-4",
    "U+1F934 U+1F3FE": "Princ, typ-5",
    "U+1F934 U+1F3FF": "Princ, typ-6",
    "U+1F470": "Nevěsta se závojem",
    "U+1F470 U+1F3FB": "Nevěsta se závojem, typ-1-2",
    "U+1F470 U+1F3FC": "Nevěsta se závojem, typ-3",
    "U+1F470 U+1F3FD": "Nevěsta se závojem, typ-4",
    "U+1F470 U+1F3FE": "Nevěsta se závojem, typ-5",
    "U+1F470 U+1F3FF": "Nevěsta se závojem, typ-6",
    "U+1F935": "Muž ve smokingu",
    "U+1F935 U+1F3FB": "Muž ve smokingu, typ-1-2",
    "U+1F935 U+1F3FC": "Muž ve smokingu, typ 3",
    "U+1F935 U+1F3FD": "Muž ve smokingu, typ 4",
    "U+1F935 U+1F3FE": "Muž ve smokingu, typ 5",
    "U+1F935 U+1F3FF": "Muž ve smokingu, typ 6",
    "U+1F930": "Těhotná žena",
    "U+1F930 U+1F3FB": "Těhotná žena, typ 1-2",
    "U+1F930 U+1F3FC": "Těhotná žena, typ 3",
    "U+1F930 U+1F3FD": "Těhotná žena, typ 4",
    "U+1F930 U+1F3FE": "Těhotná žena, typ 5",
    "U+1F930 U+1F3FF": "Těhotná žena, typ 6",
    "U+1F472": "Muž s gua pi mao ≊ muž s čínskou čepicí",
    "U+1F472 U+1F3FB": "Muž s čínskou čepicí, typ-1-2",
    "U+1F472 U+1F3FC": "Muž s čínskou čepicí, typ 3",
    "U+1F472 U+1F3FD": "Muž s čínskou čepicí, typ 4",
    "U+1F472 U+1F3FE": "Muž s čínskou čepicí, typ 5",
    "U+1F472 U+1F3FF": "Muž s čínskou čepicí, typ 6",
    "U+1F64D": "Člověk se mračí",
    "U+1F64D U+1F3FB": "Mračící se člověk, typ-1-2",
    "U+1F64D U+1F3FC": "Mračící se člověk, typ 3",
    "U+1F64D U+1F3FD": "Mračící se člověk, typ 4",
    "U+1F64D U+1F3FE": "Mračící se člověk, typ 5",
    "U+1F64D U+1F3FF": "Mračící se člověk, typ 6",
    "U+1F64E": "Osoba s našpuleným obličejem ≊ osoba našpulená",
    "U+1F64E U+1F3FB": "Osoba našpulená, typ-1-2",
    "U+1F64E U+1F3FC": "Osoba našpulená, typ 3",
    "U+1F64E U+1F3FD": "Osoba našpulená, typ 4",
    "U+1F64E U+1F3FE": "Osoba našpulená, typ 5",
    "U+1F64E U+1F3FF": "Osoba našpulená, typ 6",
    "U+1F645": "Obličej bez dobrého gesta ≊ gestikulující osoba není v pořádku",
    "U+1F645 U+1F3FB": "Gestikulující osoba není v pořádku, typ-1-2",
    "U+1F645 U+1F3FC": "Gestikulující osoba není v pořádku, typ 3",
    "U+1F645 U+1F3FD": "Gestikulující osoba není v pořádku, typ 4",
    "U+1F645 U+1F3FE": "Gestikulující osoba není v pořádku, typ 5",
    "U+1F645 U+1F3FF": "Gestikulující osoba není v pořádku, typ 6",
    "U+1F646": "Obličej s gestem OK ≊ osoba gestikuluje dobře",
    "U+1F646 U+1F3FB": "Osoba gestikuluje dobře, typ-1-2",
    "U+1F646 U+1F3FC": "Osoba gestikuluje dobře, typ 3",
    "U+1F646 U+1F3FD": "Osoba gestikuluje dobře, typ 4",
    "U+1F646 U+1F3FE": "Osoba gestikuluje dobře, typ 5",
    "U+1F646 U+1F3FF": "Osoba gestikuluje dobře, typ-6",
    "U+1F481": "Informační pult osoba ≊ osoba sklápějící ruku",
    "U+1F481 U+1F3FB": "Osoba sklápěcí ruka, typ-1-2",
    "U+1F481 U+1F3FC": "Osoba sklápějící ruku, typ-3",
    "U+1F481 U+1F3FD": "Osoba sklápěcí ruka, typ-4",
    "U+1F481 U+1F3FE": "Osoba sklápěcí ruka, typ-5",
    "U+1F481 U+1F3FF": "Osoba sklápěcí ruka, typ-6",
    "U+1F64B": "Šťastný člověk zvedající jednu ruku ≊ člověk zvedající ruku",
    "U+1F64B U+1F3FB": "Osoba zvedající ruku, typ-1-2",
    "U+1F64B U+1F3FC": "Osoba zvedající ruku, typ 3",
    "U+1F64B U+1F3FD": "Osoba zvedající ruku, typ 4",
    "U+1F64B U+1F3FE": "Osoba zvedající ruku, typ 5",
    "U+1F64B U+1F3FF": "Osoba zvedající ruku, typ 6",
    "U+1F647": "Osoba se hluboce ukloní ≊ osoba se ukloní",
    "U+1F647 U+1F3FB": "Osoba se uklání, typ-1-2",
    "U+1F647 U+1F3FC": "Osoba uklánějící se, typ 3",
    "U+1F647 U+1F3FD": "Osoba uklánějící se, typ 4",
    "U+1F647 U+1F3FE": "Osoba uklánějící se, typ 5",
    "U+1F647 U+1F3FF": "Osoba uklánějící se, typ 6",
    "U+1F926": "Obličej dlaň ≊ osoba facepalming",
    "U+1F926 U+1F3FB": "Person facepalming, typ-1-2",
    "U+1F926 U+1F3FC": "Osoba facepalming, typ-3",
    "U+1F926 U+1F3FD": "Person facepalming, typ-4",
    "U+1F926 U+1F3FE": "Osoba facepalming, typ-5",
    "U+1F926 U+1F3FF": "Person facepalming, typ-6",
    "U+1F937": "Pokrčit rameny ≊ člověk krčí rameny",
    "U+1F937 U+1F3FB": "Osoba krčící rameny, typ-1-2",
    "U+1F937 U+1F3FC": "Osoba krčící rameny, typ 3",
    "U+1F937 U+1F3FD": "Osoba krčící rameny, typ 4",
    "U+1F937 U+1F3FE": "Osoba krčící rameny, typ 5",
    "U+1F937 U+1F3FF": "Osoba krčící rameny, typ 6",
    "U+1F486": "Masáž obličeje ≊ osoba na masáži",
    "U+1F486 U+1F3FB": "Osoba na masáži, typ 1-2",
    "U+1F486 U+1F3FC": "Osoba na masáži, typ 3",
    "U+1F486 U+1F3FD": "Osoba na masáži, typ 4",
    "U+1F486 U+1F3FE": "Osoba na masáži, typ 5",
    "U+1F486 U+1F3FF": "Osoba na masáži, typ 6",
    "U+1F487": "Ostříhat ≊ ostříhaná osoba",
    "U+1F487 U+1F3FB": "Ostříhaná osoba, typ 1-2",
    "U+1F487 U+1F3FC": "Ostříhaná osoba, typ 3",
    "U+1F487 U+1F3FD": "Ostříhaná osoba, typ 4",
    "U+1F487 U+1F3FE": "Člověk se ostříhá, typ 5",
    "U+1F487 U+1F3FF": "Ostříhaná osoba, typ 6",
    "U+1F6B6": "Chodec ≊ chodící osoba",
    "U+1F6B6 U+1F3FB": "Osoba chodící, typ-1-2",
    "U+1F6B6 U+1F3FC": "Osoba chodící, typ 3",
    "U+1F6B6 U+1F3FD": "Osoba chodící, typ 4",
    "U+1F6B6 U+1F3FE": "Osoba chodící, typ-5",
    "U+1F6B6 U+1F3FF": "Osoba chodící, typ-6",
    "U+1F3C3": "Běžec ≊ člověk běžící",
    "U+1F3C3 U+1F3FB": "Běžící osoba, typ-1-2",
    "U+1F3C3 U+1F3FC": "Běžící osoba, typ 3",
    "U+1F3C3 U+1F3FD": "Běžící osoba, typ 4",
    "U+1F3C3 U+1F3FE": "Běžící osoba, typ 5",
    "U+1F3C3 U+1F3FF": "Běžící osoba, typ 6",
    "U+1F483": "Tanečnice ≊ žena tančí",
    "U+1F483 U+1F3FB": "Žena tančí, typ 1-2",
    "U+1F483 U+1F3FC": "Žena tančí, typ 3",
    "U+1F483 U+1F3FD": "Žena tančí, typ 4",
    "U+1F483 U+1F3FE": "Žena tančí, typ 5",
    "U+1F483 U+1F3FF": "Žena tančí, typ 6",
    "U+1F57A": "Muž tančí",
    "U+1F57A U+1F3FB": "Muž tančí, typ 1-2",
    "U+1F57A U+1F3FC": "Muž tančí, typ 3",
    "U+1F57A U+1F3FD": "Muž tančí, typ 4",
    "U+1F57A U+1F3FE": "Muž tančí, typ 5",
    "U+1F57A U+1F3FF": "Muž tančí, typ 6",
    "U+1F46F": "Žena s ušima ≊ lidé na párty",
    "U+1F574": "Muž v obleku levitující",
    "U+1F5E3": "Mluvící hlava v siluetě ≊ mluvící hlava",
    "U+1F464": "Poprsí v siluetě",
    "U+1F465": "Poprsí v siluetě",
    "U+1F46B": "Muž a žena drží za ruce",
    "U+1F46C": "Dva muži držící se za ruce",
    "U+1F46D": "Dvě ženy držící se za ruce",
    "U+1F48F": "Polibek",
    "U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468": "Polibek, žena, muž",
    "U+1F468 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F468": "Polibek, chlape, chlape",
    "U+1F469 U+200D U+2764 U+FE0F U+200D U+1F48B U+200D U+1F469": "Polibek, žena, žena",
    "U+1F491": "Pár se srdcem",
    "U+1F469 U+200D U+2764 U+FE0F U+200D U+1F468": "Pár se srdcem, žena, muž",
    "U+1F468 U+200D U+2764 U+FE0F U+200D U+1F468": "Pár se srdcem, muž, muž",
    "U+1F469 U+200D U+2764 U+FE0F U+200D U+1F469": "Pár se srdcem, žena, žena",
    "U+1F46A": "Rodina",
    "U+1F468 U+200D U+1F469 U+200D U+1F466": "Rodina, muž, žena, chlapec",
    "U+1F468 U+200D U+1F469 U+200D U+1F467": "Rodina, muž, žena, dívka",
    "U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466": "Rodina, muž, žena, dívka, chlapec",
    "U+1F468 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466": "Rodina, muž, žena, chlapec, chlapec",
    "U+1F468 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467": "Rodina, muž, žena, dívka, dívka",
    "U+1F468 U+200D U+1F468 U+200D U+1F466": "Rodina, muž, muž, chlapec",
    "U+1F468 U+200D U+1F468 U+200D U+1F467": "Rodina, muž, muž, dívka",
    "U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F466": "Rodina, muž, muž, dívka, chlapec",
    "U+1F468 U+200D U+1F468 U+200D U+1F466 U+200D U+1F466": "Rodina, muž, muž, chlapec, chlapec",
    "U+1F468 U+200D U+1F468 U+200D U+1F467 U+200D U+1F467": "Rodina, muž, muž, dívka, dívka",
    "U+1F469 U+200D U+1F469 U+200D U+1F466": "Rodina, žena, žena, chlapec",
    "U+1F469 U+200D U+1F469 U+200D U+1F467": "Rodina, žena, žena, dívka",
    "U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F466": "Rodina, žena, žena, dívka, chlapec",
    "U+1F469 U+200D U+1F469 U+200D U+1F466 U+200D U+1F466": "Rodina, žena, žena, chlapec, chlapec",
    "U+1F469 U+200D U+1F469 U+200D U+1F467 U+200D U+1F467": "Rodina, žena, žena, dívka, dívka",
    "U+1F3FB": "Modifikátor emoji fitzpatrick typ-1-2 ≊ typ-1-2",
    "U+1F3FC": "Modifikátor emodži fitzpatrick typ-3 ≊ typ-3",
    "U+1F3FD": "Modifikátor emodži fitzpatrick typ-4 ≊ typ-4",
    "U+1F3FE": "Modifikátor emodži fitzpatrick typ-5 ≊ typ-5",
    "U+1F3FF": "Modifikátor emodži fitzpatrick typ-6 ≊ typ-6",
    "U+1F4AA": "Ohnuté bicepsy",
    "U+1F4AA U+1F3FB": "Flektovaný biceps, typ-1-2",
    "U+1F4AA U+1F3FC": "Flektovaný biceps, typ 3",
    "U+1F4AA U+1F3FD": "Flektovaný biceps, typ 4",
    "U+1F4AA U+1F3FE": "Flektovaný biceps, typ 5",
    "U+1F4AA U+1F3FF": "Flektovaný biceps, typ 6",
    "U+1F933": "Selfie",
    "U+1F933 U+1F3FB": "Selfie, typ 1-2",
    "U+1F933 U+1F3FC": "Selfie, typ 3",
    "U+1F933 U+1F3FD": "Selfie, typ 4",
    "U+1F933 U+1F3FE": "Selfie, typ 5",
    "U+1F933 U+1F3FF": "Selfie, typ 6",
    "U+1F448": "Bílá levá směřující backhand index ≊ backhand index směřující doleva",
    "U+1F448 U+1F3FB": "Backhand index směřující doleva, typ-1-2",
    "U+1F448 U+1F3FC": "Bekhendový index směřující doleva, typ-3",
    "U+1F448 U+1F3FD": "Bekhendový index směřující doleva, typ-4",
    "U+1F448 U+1F3FE": "Bekhendový index směřující doleva, typ-5",
    "U+1F448 U+1F3FF": "Bekhendový index směřující doleva, typ-6",
    "U+1F449": "Bílý pravý ukazováček bekhendu ≊ bekhendový index směřující doprava",
    "U+1F449 U+1F3FB": "Backhand index směřující doprava, typ-1-2",
    "U+1F449 U+1F3FC": "Backhand index směřující doprava, typ-3",
    "U+1F449 U+1F3FD": "Backhand index směřující doprava, typ-4",
    "U+1F449 U+1F3FE": "Backhand index směřující doprava, typ-5",
    "U+1F449 U+1F3FF": "Backhand index směřující doprava, typ-6",
    "U+261D": "White up pointing index ≊ index směřující nahoru",
    "U+261D U+1F3FB": "Index směřující nahoru, typ-1-2",
    "U+261D U+1F3FC": "Index směřující nahoru, typ-3",
    "U+261D U+1F3FD": "Index směřující nahoru, typ-4",
    "U+261D U+1F3FE": "Index směřující nahoru, typ-5",
    "U+261D U+1F3FF": "Index směřující nahoru, typ-6",
    "U+1F446": "White up pointing backhand index ≊ backhand index směřující nahoru",
    "U+1F446 U+1F3FB": "Backhand index směřující nahoru, typ-1-2",
    "U+1F446 U+1F3FC": "Backhand index směřující nahoru, typ-3",
    "U+1F446 U+1F3FD": "Backhand index směřující nahoru, typ-4",
    "U+1F446 U+1F3FE": "Backhand index směřující nahoru, typ-5",
    "U+1F446 U+1F3FF": "Backhand index směřující nahoru, typ-6",
    "U+1F595": "Obrácená ruka s nataženým prostředníkem ≊ prostředníček",
    "U+1F595 U+1F3FB": "Prostředníček, typ-1-2",
    "U+1F595 U+1F3FC": "Prostředníček, typ-3",
    "U+1F595 U+1F3FD": "Prostředníček, typ-4",
    "U+1F595 U+1F3FE": "Prostředníček, typ 5",
    "U+1F595 U+1F3FF": "Prostředníček, typ 6",
    "U+1F447": "White down pointing backhand index ≊ backhand index pointing down",
    "U+1F447 U+1F3FB": "Backhand index směřující dolů, typ-1-2",
    "U+1F447 U+1F3FC": "Backhand index směřující dolů, typ-3",
    "U+1F447 U+1F3FD": "Backhand index směřující dolů, typ-4",
    "U+1F447 U+1F3FE": "Backhand index směřující dolů, typ-5",
    "U+1F447 U+1F3FF": "Backhand index směřující dolů, typ-6",
    "U+270C": "Vítězná ruka",
    "U+270C U+1F3FB": "Vítězná ruka, typ-1-2",
    "U+270C U+1F3FC": "Vítězná ruka, typ 3",
    "U+270C U+1F3FD": "Vítězná ruka, typ 4",
    "U+270C U+1F3FE": "Vítězná ruka, typ 5",
    "U+270C U+1F3FF": "Vítězná ruka, typ 6",
    "U+1F91E": "Ruka se zkříženýma ukazováčkem a prostředníčkem ≊ zkřížené prsty",
    "U+1F91E U+1F3FB": "Zkřížené prsty, typ-1-2",
    "U+1F91E U+1F3FC": "Zkřížené prsty, typ 3",
    "U+1F91E U+1F3FD": "Zkřížené prsty, typ 4",
    "U+1F91E U+1F3FE": "Zkřížené prsty, typ 5",
    "U+1F91E U+1F3FF": "Zkřížené prsty, typ 6",
    "U+1F596": "Zvednutá ruka s částí mezi prostředníčkem a prsteníčkem ≊ vulkánský pozdrav",
    "U+1F596 U+1F3FB": "Vulkánský pozdrav, typ 1-2",
    "U+1F596 U+1F3FC": "Vulkánský pozdrav, typ 3",
    "U+1F596 U+1F3FD": "Vulkánský pozdrav, typ 4",
    "U+1F596 U+1F3FE": "Vulkánský pozdrav, typ 5",
    "U+1F596 U+1F3FF": "Vulkánský pozdrav, typ 6",
    "U+1F918": "Znamení rohů",
    "U+1F918 U+1F3FB": "Znamení rohů, typ-1-2",
    "U+1F918 U+1F3FC": "Znamení rohů, typ 3",
    "U+1F918 U+1F3FD": "Znamení rohů, typ-4",
    "U+1F918 U+1F3FE": "Znamení rohů, typ 5",
    "U+1F918 U+1F3FF": "Znamení rohů, typ 6",
    "U+1F919": "Zavolej mi ruku",
    "U+1F919 U+1F3FB": "Zavolej mi ruku, typ-1-2",
    "U+1F919 U+1F3FC": "Zavolej mi ruku, typ 3",
    "U+1F919 U+1F3FD": "Zavolej mi ruku, typ 4",
    "U+1F919 U+1F3FE": "Zavolej mi ruku, typ-5",
    "U+1F919 U+1F3FF": "Zavolej mi ruku, typ-6",
    "U+1F590": "Zdvižená ruka s roztaženými prsty",
    "U+1F590 U+1F3FB": "Zvednutá ruka s roztaženými prsty, typ-1-2",
    "U+1F590 U+1F3FC": "Zvednutá ruka s roztaženými prsty, typ 3",
    "U+1F590 U+1F3FD": "Zvednutá ruka s roztaženými prsty, typ 4",
    "U+1F590 U+1F3FE": "Zvednutá ruka s roztaženými prsty, typ 5",
    "U+1F590 U+1F3FF": "Zvednutá ruka s roztaženými prsty, typ 6",
    "U+270B": "Zvednutá ruka",
    "U+270B U+1F3FB": "Zvednutá ruka, typ-1-2",
    "U+270B U+1F3FC": "Zvednutá ruka, typ-3",
    "U+270B U+1F3FD": "Zvednutá ruka, typ-4",
    "U+270B U+1F3FE": "Zvednutá ruka, typ-5",
    "U+270B U+1F3FF": "Zvednutá ruka, typ 6",
    "U+1F44C": "Ok ruka znamení ≊ ok ruka",
    "U+1F44C U+1F3FB": "Dobrá ruka, typ-1-2",
    "U+1F44C U+1F3FC": "Dobrá ruka, typ 3",
    "U+1F44C U+1F3FD": "Dobrá ruka, typ-4",
    "U+1F44C U+1F3FE": "Dobrá ruka, typ-5",
    "U+1F44C U+1F3FF": "Dobrá ruka, typ-6",
    "U+1F44D": "Palec nahoru znamení ≊ palec nahoru",
    "U+1F44D U+1F3FB": "Palec nahoru, typ-1-2",
    "U+1F44D U+1F3FC": "Palec nahoru, typ 3",
    "U+1F44D U+1F3FD": "Palec nahoru, typ 4",
    "U+1F44D U+1F3FE": "Palec nahoru, typ 5",
    "U+1F44D U+1F3FF": "Palec nahoru, typ 6",
    "U+1F44E": "Palec dolů znamení ≊ palec dolů",
    "U+1F44E U+1F3FB": "Palec dolů, typ-1-2",
    "U+1F44E U+1F3FC": "Palec dolů, typ 3",
    "U+1F44E U+1F3FD": "Palec dolů, typ-4",
    "U+1F44E U+1F3FE": "Palec dolů, typ-5",
    "U+1F44E U+1F3FF": "Palec dolů, typ-6",
    "U+270A": "Zvednutá pěst",
    "U+270A U+1F3FB": "Zvednutá pěst, typ-1-2",
    "U+270A U+1F3FC": "Zvednutá pěst, typ 3",
    "U+270A U+1F3FD": "Zvednutá pěst, typ 4",
    "U+270A U+1F3FE": "Zvednutá pěst, typ 5",
    "U+270A U+1F3FF": "Zvednutá pěst, typ 6",
    "U+1F44A": "Znamení pěstí ruky ≊ blížící se pěst",
    "U+1F44A U+1F3FB": "Blížící se pěst, typ-1-2",
    "U+1F44A U+1F3FC": "Blížící se pěst, typ 3",
    "U+1F44A U+1F3FD": "Blížící se pěst, typ 4",
    "U+1F44A U+1F3FE": "Blížící se pěst, typ 5",
    "U+1F44A U+1F3FF": "Blížící se pěst, typ 6",
    "U+1F91B": "Levou pěstí",
    "U+1F91B U+1F3FB": "Levá pěst, typ-1-2",
    "U+1F91B U+1F3FC": "Levá pěst, typ 3",
    "U+1F91B U+1F3FD": "Levá pěst, typ 4",
    "U+1F91B U+1F3FE": "Levá pěst, typ 5",
    "U+1F91B U+1F3FF": "Levá pěst, typ 6",
    "U+1F91C": "Pěstí směřující vpravo",
    "U+1F91C U+1F3FB": "Pěst směřující vpravo, typ-1-2",
    "U+1F91C U+1F3FC": "Pěst směřující vpravo, typ 3",
    "U+1F91C U+1F3FD": "Pěst směřující vpravo, typ 4",
    "U+1F91C U+1F3FE": "Pěst směřující vpravo, typ 5",
    "U+1F91C U+1F3FF": "Pěst směřující vpravo, typ 6",
    "U+1F91A": "Zvednutý hřbet ruky",
    "U+1F91A U+1F3FB": "Zvednutý hřbet ruky, typ-1-2",
    "U+1F91A U+1F3FC": "Zvednutý hřbet ruky, typ 3",
    "U+1F91A U+1F3FD": "Zvednutý hřbet ruky, typ 4",
    "U+1F91A U+1F3FE": "Zvednutý hřbet ruky, typ-5",
    "U+1F91A U+1F3FF": "Zvednutý hřbet ruky, typ 6",
    "U+1F44B": "Znamení mávající ruky ≊ mávající rukou",
    "U+1F44B U+1F3FB": "Mávající rukou, typ-1-2",
    "U+1F44B U+1F3FC": "Mávající rukou, typ 3",
    "U+1F44B U+1F3FD": "Mávající rukou, typ 4",
    "U+1F44B U+1F3FE": "Mávající rukou, typ 5",
    "U+1F44B U+1F3FF": "Mávající rukou, typ 6",
    "U+1F44F": "Tleskání rukou znamení ≊ tleskání rukou",
    "U+1F44F U+1F3FB": "Tleskání rukou, typ-1-2",
    "U+1F44F U+1F3FC": "Tleskání rukou, typ 3",
    "U+1F44F U+1F3FD": "Tleskání rukou, typ 4",
    "U+1F44F U+1F3FE": "Tleskání rukou, typ 5",
    "U+1F44F U+1F3FF": "Tleskání rukou, typ 6",
    "U+270D": "Psací rukou",
    "U+270D U+1F3FB": "Psací ruka, typ-1-2",
    "U+270D U+1F3FC": "Psací rukou, typ-3",
    "U+270D U+1F3FD": "Psací ruka, typ-4",
    "U+270D U+1F3FE": "Psací ruka, typ-5",
    "U+270D U+1F3FF": "Psací ruka, typ-6",
    "U+1F450": "Znak otevřených rukou ≊ otevřené ruce",
    "U+1F450 U+1F3FB": "Otevřené ruce, typ-1-2",
    "U+1F450 U+1F3FC": "Otevřené ruce, typ-3",
    "U+1F450 U+1F3FD": "Otevřené ruce, typ-4",
    "U+1F450 U+1F3FE": "Otevřené ruce, typ-5",
    "U+1F450 U+1F3FF": "Otevřené ruce, typ-6",
    "U+1F64C": "Osoba zvedající obě ruce na oslavu ≊ osoba zvedající ruce",
    "U+1F64C U+1F3FB": "Osoba zvedající ruce, typ-1-2",
    "U+1F64C U+1F3FC": "Osoba zvedající ruce, typ 3",
    "U+1F64C U+1F3FD": "Osoba zvedající ruce, typ 4",
    "U+1F64C U+1F3FE": "Osoba zvedající ruce, typ 5",
    "U+1F64C U+1F3FF": "Osoba zvedající ruce, typ 6",
    "U+1F64F": "Osoba se sepjatýma rukama ≊ sepjatýma rukama",
    "U+1F64F U+1F3FB": "Sepjatýma rukama, typ-1-2",
    "U+1F64F U+1F3FC": "Sepjatýma rukama, typ 3",
    "U+1F64F U+1F3FD": "Sepjatýma rukama, typ-4",
    "U+1F64F U+1F3FE": "Sepjatýma rukama, typ-5",
    "U+1F64F U+1F3FF": "Sepjatýma rukama, typ-6",
    "U+1F91D": "Podání ruky",
    "U+1F91D U+1F3FB": "Podání ruky, typ-1-2",
    "U+1F91D U+1F3FC": "Potřesení rukou, typ 3",
    "U+1F91D U+1F3FD": "Potřesení rukou, typ 4",
    "U+1F91D U+1F3FE": "Potřesení rukou, typ 5",
    "U+1F91D U+1F3FF": "Potřesení rukou, typ 6",
    "U+1F485": "Lak na nehty",
    "U+1F485 U+1F3FB": "Lak na nehty, typ-1-2",
    "U+1F485 U+1F3FC": "Lak na nehty, typ 3",
    "U+1F485 U+1F3FD": "Lak na nehty, typ 4",
    "U+1F485 U+1F3FE": "Lak na nehty, typ-5",
    "U+1F485 U+1F3FF": "Lak na nehty, typ-6",
    "U+1F442": "Ucho",
    "U+1F442 U+1F3FB": "Ucho, typ-1-2",
    "U+1F442 U+1F3FC": "Ucho, typ-3",
    "U+1F442 U+1F3FD": "Ucho, typ-4",
    "U+1F442 U+1F3FE": "Ucho, typ-5",
    "U+1F442 U+1F3FF": "Ucho, typ-6",
    "U+1F443": "Nos",
    "U+1F443 U+1F3FB": "Nos, typ-1-2",
    "U+1F443 U+1F3FC": "Nos, typ-3",
    "U+1F443 U+1F3FD": "Nos, typ-4",
    "U+1F443 U+1F3FE": "Nos, typ-5",
    "U+1F443 U+1F3FF": "Nos, typ-6",
    "U+1F463": "Stopy",
    "U+1F440": "Oči",
    "U+1F441": "Oko",
    "U+1F441 U+200D U+1F5E8": "Oko, levá bublina",
    "U+1F445": "Jazyk",
    "U+1F444": "Ústa",
    "U+1F48B": "Znak polibku",
    "U+1F498": "Srdce s šipkou",
    "U+2764": "Těžké černé srdce ≊ červené srdce",
    "U+1F493": "Bijící srdce",
    "U+1F494": "Zlomené srdce",
    "U+1F495": "Dvě srdce",
    "U+1F496": "Jiskřivé srdce",
    "U+1F497": "Rostoucí srdce",
    "U+1F499": "Modré srdce",
    "U+1F49A": "Zelené srdce",
    "U+1F49B": "Žluté srdce",
    "U+1F49C": "Fialové srdce",
    "U+1F5A4": "Černé srdce",
    "U+1F49D": "Srdce s mašlí",
    "U+1F49E": "Otáčející se srdce",
    "U+1F49F": "Dekorace srdce",
    "U+2763": "Těžké srdce vykřičník ornament ≊ těžké srdce vykřičník",
    "U+1F48C": "Milostný dopis",
    "U+1F4A4": "Symbol spánku ≊ zzz",
    "U+1F4A2": "Symbol hněvu",
    "U+1F4A3": "Bombardovat",
    "U+1F4A5": "Symbol kolize ≊ kolize",
    "U+1F4A6": "Symbol stříkajícího potu ≊ kapky potu",
    "U+1F4A8": "Symbol pomlčky ≊ přerušovaná",
    "U+1F4AB": "Závrať symbol ≊ závrať",
    "U+1F4AC": "Řečový balónek",
    "U+1F5E8": "Levá bublina",
    "U+1F5EF": "Bublina pravého hněvu",
    "U+1F4AD": "Myšlenkový balón",
    "U+1F573": "Otvor",
    "U+1F453": "Dioptrické brýle ≊ brýle",
    "U+1F576": "Tmavé sluneční brýle ≊ sluneční brýle",
    "U+1F454": "Vázanka",
    "U+1F455": "Tričko",
    "U+1F456": "Džíny",
    "U+1F457": "Šaty",
    "U+1F458": "Kimono",
    "U+1F459": "Bikiny",
    "U+1F45A": "Dámské oblečení ≊ dámské oblečení",
    "U+1F45B": "Peněženka",
    "U+1F45C": "Kabelka",
    "U+1F45D": "Vak",
    "U+1F6CD": "Nákupní tašky",
    "U+1F392": "Školní brašna ≊ školní batoh",
    "U+1F45E": "Pánská bota ≊ pánská bota",
    "U+1F45F": "Atletická bota ≊ běžecká bota",
    "U+1F460": "Bota na vysokém podpatku",
    "U+1F461": "Dámský sandál ≊ dámský sandál",
    "U+1F462": "Dámské boty ≊ dámské boty",
    "U+1F451": "Koruna",
    "U+1F452": "Dámský klobouk ≊ dámský klobouk",
    "U+1F3A9": "Cylindr",
    "U+1F393": "Promoční čepice",
    "U+26D1": "Přilba s bílým křížem",
    "U+1F4FF": "Modlitební korálky",
    "U+1F484": "Rtěnka",
    "U+1F48D": "Kroužek",
    "U+1F48E": "Drahokam",
    "U+1F435": "Opičí obličej",
    "U+1F412": "Opice",
    "U+1F98D": "Gorila",
    "U+1F436": "Psí tvář",
    "U+1F415": "Pes",
    "U+1F429": "Pudl",
    "U+1F43A": "Vlčí tvář",
    "U+1F98A": "Liščí tvář",
    "U+1F431": "Kočičí tvář",
    "U+1F408": "Kočka",
    "U+1F981": "Lví tvář",
    "U+1F42F": "Tygří tvář",
    "U+1F405": "Tygr",
    "U+1F406": "Leopard",
    "U+1F434": "Koňská tvář",
    "U+1F40E": "Kůň",
    "U+1F98C": "Jelen",
    "U+1F984": "Obličej jednorožce",
    "U+1F42E": "Kravský obličej",
    "U+1F402": "Vůl",
    "U+1F403": "Vodní buvol",
    "U+1F404": "Kráva",
    "U+1F437": "Prasečí tvář",
    "U+1F416": "Prase",
    "U+1F417": "Kanec",
    "U+1F43D": "Prasečí nos",
    "U+1F40F": "Beran",
    "U+1F411": "Ovce",
    "U+1F410": "Koza",
    "U+1F42A": "Velbloud dromedár ≊ velbloud",
    "U+1F42B": "Velbloud dvouhrbý ≊ velbloud dvouhrbý",
    "U+1F418": "Slon",
    "U+1F98F": "Nosorožec",
    "U+1F42D": "Myší obličej",
    "U+1F401": "Myš",
    "U+1F400": "Krysa",
    "U+1F439": "Křeččí obličej",
    "U+1F430": "Králičí tvář",
    "U+1F407": "Králičí",
    "U+1F43F": "Chipmunk",
    "U+1F987": "Netopýr",
    "U+1F43B": "Medvědí tvář",
    "U+1F428": "Koala",
    "U+1F43C": "Tvář pandy",
    "U+1F43E": "Otisky tlapek",
    "U+1F983": "Turecko",
    "U+1F414": "Kuře",
    "U+1F413": "Kohout",
    "U+1F423": "Líhnoucí se mládě",
    "U+1F424": "Mládě kuřátko",
    "U+1F425": "Kuřátko směřující dopředu",
    "U+1F426": "Pták",
    "U+1F427": "Tučňák",
    "U+1F54A": "Holubice míru ≊ holubice",
    "U+1F985": "Orel",
    "U+1F986": "Kachna",
    "U+1F989": "Sova",
    "U+1F438": "Žabí obličej",
    "U+1F40A": "Krokodýl",
    "U+1F422": "Želva",
    "U+1F98E": "Ještěrka",
    "U+1F40D": "Had",
    "U+1F432": "Dračí tvář",
    "U+1F409": "Drak",
    "U+1F433": "Chrlí velryba",
    "U+1F40B": "Velryba",
    "U+1F42C": "Delfín",
    "U+1F41F": "Ryba",
    "U+1F420": "Tropické ryby",
    "U+1F421": "Nafukovací ryba",
    "U+1F988": "Žralok",
    "U+1F419": "Chobotnice",
    "U+1F41A": "Spirálový plášť",
    "U+1F980": "Krab",
    "U+1F990": "Krevety",
    "U+1F991": "Chobotnice",
    "U+1F98B": "Motýl",
    "U+1F40C": "Šnek",
    "U+1F41B": "Chyba",
    "U+1F41C": "Mravenec",
    "U+1F41D": "Včela",
    "U+1F41E": "Paní brouk",
    "U+1F577": "Pavouk",
    "U+1F578": "Pavučina",
    "U+1F982": "Štír",
    "U+1F490": "Kytice",
    "U+1F338": "Třešňový květ",
    "U+1F4AE": "Bílý květ",
    "U+1F3F5": "Růžice",
    "U+1F339": "Růže",
    "U+1F940": "Zvadlý květ",
    "U+1F33A": "Ibišek",
    "U+1F33B": "Slunečnice",
    "U+1F33C": "Květ",
    "U+1F337": "Tulipán",
    "U+1F331": "Sazenice",
    "U+1F332": "Stálezelený strom ≊ stálezelený",
    "U+1F333": "Listnatý strom",
    "U+1F334": "Palma",
    "U+1F335": "Kaktus",
    "U+1F33E": "Klas rýže ≊ svazek rýže",
    "U+1F33F": "Bylina",
    "U+2618": "Trojlístek",
    "U+1F340": "Čtyřlístek",
    "U+1F341": "Javorový list",
    "U+1F342": "Spadaný list",
    "U+1F343": "Listí vlající ve větru",
    "U+1F347": "Hrozny",
    "U+1F348": "Meloun",
    "U+1F349": "Vodní meloun",
    "U+1F34A": "Mandarinka",
    "U+1F34B": "Citrón",
    "U+1F34C": "Banán",
    "U+1F34D": "Ananas",
    "U+1F34E": "Červené jablko",
    "U+1F34F": "Zelené jablko",
    "U+1F350": "Hruška",
    "U+1F351": "Broskev",
    "U+1F352": "Třešně",
    "U+1F353": "Jahoda",
    "U+1F95D": "Kiwi",
    "U+1F345": "Rajče",
    "U+1F951": "Avokádo",
    "U+1F346": "Lilek ≊ lilek",
    "U+1F954": "Brambor",
    "U+1F955": "Mrkev",
    "U+1F33D": "Klas kukuřice ≊ klas kukuřice",
    "U+1F336": "Pálivá paprika",
    "U+1F952": "Okurka",
    "U+1F344": "Houba",
    "U+1F95C": "Arašídy",
    "U+1F330": "Kaštan",
    "U+1F35E": "Chléb",
    "U+1F950": "Rohlík",
    "U+1F956": "Bagetový chléb",
    "U+1F95E": "Palačinky",
    "U+1F9C0": "Sýrový klínek",
    "U+1F356": "Maso na kosti",
    "U+1F357": "Drůbeží stehno",
    "U+1F953": "Slanina",
    "U+1F354": "Hamburger",
    "U+1F35F": "Hranolky",
    "U+1F355": "Plátek pizzy ≊ pizza",
    "U+1F32D": "Párek v rohlíku",
    "U+1F32E": "Taco",
    "U+1F32F": "Burrito",
    "U+1F959": "Plněný mazanec",
    "U+1F95A": "Vejce",
    "U+1F373": "Vaření",
    "U+1F958": "Mělká pánev s jídlem",
    "U+1F372": "Hrnec jídla",
    "U+1F957": "Zelený salát",
    "U+1F37F": "Popcorn",
    "U+1F371": "Bento box",
    "U+1F358": "Rýžový krekr",
    "U+1F359": "Rýžová koule",
    "U+1F35A": "Vařená rýže",
    "U+1F35B": "Kari a rýže ≊ kari rýže",
    "U+1F35C": "Napařovací mísa",
    "U+1F35D": "Špagety",
    "U+1F360": "Pečené sladké brambory",
    "U+1F362": "Oden",
    "U+1F363": "Sushi",
    "U+1F364": "Smažené krevety",
    "U+1F365": "Rybí dort s vírovým designem ≊ rybí dort s vírem",
    "U+1F361": "Dango",
    "U+1F366": "Měkká zmrzlina",
    "U+1F367": "Oholený led",
    "U+1F368": "Zmrzlina",
    "U+1F369": "Kobliha",
    "U+1F36A": "Cookie",
    "U+1F382": "Narozeninový dort",
    "U+1F370": "Koláč",
    "U+1F36B": "Čokoládová tyčinka",
    "U+1F36C": "Bonbón",
    "U+1F36D": "Lízátko",
    "U+1F36E": "Pudink",
    "U+1F36F": "Medový hrnec",
    "U+1F37C": "Kojenecká láhev",
    "U+1F95B": "Sklenici mléka",
    "U+2615": "Horký nápoj",
    "U+1F375": "Šálek bez ouška",
    "U+1F376": "Saké láhev a šálek ≊ saké",
    "U+1F37E": "Láhev s praskajícím korkem",
    "U+1F377": "Sklenice na víno",
    "U+1F378": "Koktejlové sklenice",
    "U+1F379": "Tropický nápoj",
    "U+1F37A": "Džbánek na pivo",
    "U+1F37B": "Cinkající půllitry",
    "U+1F942": "Cinkání sklenic",
    "U+1F943": "Sklenice sklenice",
    "U+1F37D": "Vidlička a nůž s deskou",
    "U+1F374": "Vidličkou a nožem",
    "U+1F944": "Lžíce",
    "U+1F52A": "Hocho ≊ kuchyňský nůž",
    "U+1F3FA": "Amfora",
    "U+1F30D": "Zeměkoule evropa-afrika ≊ zeměkoule zobrazující evropa-afrika",
    "U+1F30E": "Zeměkoule ameriky ≊ zeměkoule zobrazující ameriky",
    "U+1F30F": "Zeměkoule Asie-Austrálie ≊ zeměkoule zobrazující Asii-Austrálii",
    "U+1F310": "Zeměkoule s poledníky",
    "U+1F5FA": "Mapa světa",
    "U+1F5FE": "Silueta Japonska ≊ mapa Japonska",
    "U+1F3D4": "Zasněžená hora ≊ zasněžená hora",
    "U+26F0": "Hora",
    "U+1F30B": "Sopka",
    "U+1F5FB": "Hora fuji",
    "U+1F3D5": "Kempování",
    "U+1F3D6": "Pláž s deštníkem",
    "U+1F3DC": "Poušť",
    "U+1F3DD": "Opuštěný ostrov",
    "U+1F3DE": "Národní park",
    "U+1F3DF": "Stadión",
    "U+1F3DB": "Klasická budova",
    "U+1F3D7": "Stavební konstrukce",
    "U+1F3D8": "Domovní budovy",
    "U+1F3D9": "Panoráma města",
    "U+1F3DA": "Opuštěná budova domu",
    "U+1F3E0": "Stavba domu",
    "U+1F3E1": "Dům se zahradou",
    "U+1F3E2": "Kancelářská budova",
    "U+1F3E3": "Japonská pošta",
    "U+1F3E4": "Evropská pošta ≊ pošta",
    "U+1F3E5": "NEMOCNICE",
    "U+1F3E6": "Banka",
    "U+1F3E8": "Hotel",
    "U+1F3E9": "Milovat hotel",
    "U+1F3EA": "Večerka",
    "U+1F3EB": "Škola",
    "U+1F3EC": "Obchodní dům",
    "U+1F3ED": "Továrna",
    "U+1F3EF": "Japonský hrad",
    "U+1F3F0": "Evropský hrad ≊ hrad",
    "U+1F492": "Svatba",
    "U+1F5FC": "Tokijská věž",
    "U+1F5FD": "Socha svobody",
    "U+26EA": "Kostel",
    "U+1F54C": "Mešita",
    "U+1F54D": "Synagoga",
    "U+26E9": "Šintoistická svatyně",
    "U+1F54B": "Kaaba",
    "U+26F2": "Kašna",
    "U+26FA": "Stan",
    "U+1F301": "Mlhavý",
    "U+1F303": "Noc s hvězdami",
    "U+1F304": "Východ slunce nad horami",
    "U+1F305": "Východ slunce",
    "U+1F306": "Panoráma města za soumraku",
    "U+1F307": "Západ slunce nad budovami ≊ západ slunce",
    "U+1F309": "Most v noci",
    "U+2668": "Horké prameny",
    "U+1F30C": "Mléčná dráha",
    "U+1F3A0": "Kolotočový kůň",
    "U+1F3A1": "Ruské kolo",
    "U+1F3A2": "Horská dráha",
    "U+1F488": "Holičská tyč",
    "U+1F3AA": "Cirkusový stan",
    "U+1F3AD": "Múzických umění",
    "U+1F5BC": "Rám s obrázkem",
    "U+1F3A8": "Umělecká paleta",
    "U+1F3B0": "Hrací automat",
    "U+1F682": "Parní lokomotiva ≊ lokomotiva",
    "U+1F683": "Železniční vůz",
    "U+1F684": "Vysokorychlostní vlak",
    "U+1F685": "Vysokorychlostní vlak s kulovým nosem",
    "U+1F686": "Vlak",
    "U+1F687": "Metro",
    "U+1F688": "Lehká kolej",
    "U+1F689": "Stanice",
    "U+1F68A": "Tramvaj",
    "U+1F69D": "Jednokolejka",
    "U+1F69E": "Horská dráha",
    "U+1F68B": "Tramvajový vůz",
    "U+1F68C": "Autobus",
    "U+1F68D": "Protijedoucí autobus",
    "U+1F68E": "Trolejbus",
    "U+1F690": "Minibus",
    "U+1F691": "Ambulance",
    "U+1F692": "Hasičská stříkačka",
    "U+1F693": "Policejní auto",
    "U+1F694": "Přijíždějící policejní auto",
    "U+1F695": "Taxi",
    "U+1F696": "Protijedoucí taxi",
    "U+1F697": "Automobil",
    "U+1F698": "Protijedoucí automobil",
    "U+1F699": "Rekreační vozidlo",
    "U+1F69A": "Dodávkový vůz",
    "U+1F69B": "Tahač s návesem",
    "U+1F69C": "Traktor",
    "U+1F6B2": "Jízdní kolo",
    "U+1F6F4": "Koloběžka ≊ koloběžka",
    "U+1F6F5": "Motorový skútr",
    "U+1F3CE": "Závodní auto",
    "U+1F3CD": "Závodní motocykl ≊ motocykl",
    "U+1F68F": "ZASTÁVKA",
    "U+1F6E3": "Dálnice",
    "U+1F6E4": "Železniční trať",
    "U+26FD": "Palivové čerpadlo",
    "U+1F6A8": "Policejní auto otočné světlo ≊ policejní auto světlo",
    "U+1F6A5": "Horizontální semafor",
    "U+1F6A6": "Vertikální semafor",
    "U+1F6A7": "Stavební znak ≊ konstrukce",
    "U+1F6D1": "Osmiboká značka ≊ stopka",
    "U+2693": "Kotva",
    "U+26F5": "Plachetnice",
    "U+1F6F6": "Kánoe",
    "U+1F6A4": "Motorový člun",
    "U+1F6F3": "Osobní loď",
    "U+26F4": "Trajekt",
    "U+1F6E5": "Motorový člun",
    "U+1F6A2": "Loď",
    "U+2708": "Letoun",
    "U+1F6E9": "Malé letadlo",
    "U+1F6EB": "Odlet letadla",
    "U+1F6EC": "Airplane arriving ≊ airplane coming",
    "U+1F4BA": "Sedadlo",
    "U+1F681": "Helikoptéra",
    "U+1F69F": "Závěsná železnice",
    "U+1F6A0": "Horská lanovka",
    "U+1F6A1": "Lanovka",
    "U+1F680": "Raketa",
    "U+1F6F0": "Satelit",
    "U+1F6CE": "Zvonek poslíček",
    "U+1F6AA": "Dveře",
    "U+1F6CC": "Ubytování na spaní ≊ osoba v posteli",
    "U+1F6CF": "Postel",
    "U+1F6CB": "Pohovka a lampa",
    "U+1F6BD": "Toaleta",
    "U+1F6BF": "Sprcha",
    "U+1F6C0": "Koupel ≊ koupající se osoba",
    "U+1F6C0 U+1F3FB": "Osoba, která se koupe, typ-1-2",
    "U+1F6C0 U+1F3FC": "Osoba, která se koupe, typ 3",
    "U+1F6C0 U+1F3FD": "Osoba, která se koupe, typ 4",
    "U+1F6C0 U+1F3FE": "Koupel, typ-5",
    "U+1F6C0 U+1F3FF": "Koupel, typ-6",
    "U+1F6C1": "Vana",
    "U+231B": "Přesýpací hodiny",
    "U+23F3": "Přesýpací hodiny s tekoucím pískem",
    "U+231A": "Hodinky",
    "U+23F0": "Budík",
    "U+23F1": "Stopky",
    "U+23F2": "Časovač hodiny",
    "U+1F570": "Krbové hodiny",
    "U+1F55B": "Ciferník dvanáct hodin ≊ dvanáct hodin",
    "U+1F567": "Ciferník dvanáct třicet ≊ dvanáct třicet",
    "U+1F550": "Ciferník jedna hodina ≊ jedna hodina",
    "U+1F55C": "Ciferník jedna třicet ≊ jedna třicet",
    "U+1F551": "Ciferník dvě hodiny ≊ dvě hodiny",
    "U+1F55D": "Ciferník půl třetí ≊ půl třetí",
    "U+1F552": "Ciferník tři hodiny ≊ tři hodiny",
    "U+1F55E": "Ciferník půl čtvrté ≊ půl čtvrté",
    "U+1F553": "Ciferník čtyři hodiny ≊ čtyři hodiny",
    "U+1F55F": "Ciferník čtyři třicet ≊ čtyři třicet",
    "U+1F554": "Ciferník pět hodin ≊ pět hodin",
    "U+1F560": "Ciferník pět třicet ≊ pět třicet",
    "U+1F555": "Ciferník šest hodin ≊ šest hodin",
    "U+1F561": "Ciferník šest třicet ≊ šest třicet",
    "U+1F556": "Ciferník sedm hodin ≊ sedm hodin",
    "U+1F562": "Ciferník sedm třicet ≊ sedm třicet",
    "U+1F557": "Ciferník osm hodin ≊ osm hodin",
    "U+1F563": "Ciferník osm třicet ≊ osm třicet",
    "U+1F558": "Ciferník devět hodin ≊ devět hodin",
    "U+1F564": "Ciferník devět třicet ≊ devět třicet",
    "U+1F559": "Ciferník deset hodin ≊ deset hodin",
    "U+1F565": "Ciferník deset třicet ≊ deset třicet",
    "U+1F55A": "Ciferník jedenáct hodin ≊ jedenáct hodin",
    "U+1F566": "Ciferník jedenáct třicet ≊ jedenáct třicet",
    "U+1F311": "Symbol novoluní ≊ novoluní",
    "U+1F312": "Dorůstající srpek symbol ≊ dorůstající srpek měsíce",
    "U+1F313": "Symbol první čtvrti ≊ první čtvrtiny měsíce",
    "U+1F314": "Dorůstající gibbous moon symbol ≊ dorůstající gibbous moon",
    "U+1F315": "Symbol úplňku ≊ úplněk",
    "U+1F316": "Symbol klesajícího gibbous moon ≊ klesající gibbous moon",
    "U+1F317": "Symbol poslední čtvrti ≊ poslední čtvrtiny měsíce",
    "U+1F318": "Symbol ubývajícího půlměsíce ≊ ubývající srpek měsíce",
    "U+1F319": "Srpek měsíce",
    "U+1F31A": "Novoluní s tváří ≊ novoluní tvář",
    "U+1F31B": "První čtvrt měsíce s tváří",
    "U+1F31C": "Poslední čtvrtina měsíce s tváří",
    "U+1F321": "Teploměr",
    "U+2600": "Černé slunce s paprsky ≊ ​​slunce",
    "U+1F31D": "Úplněk s tváří",
    "U+1F31E": "Slunce s tváří",
    "U+2B50": "Bílá střední hvězda",
    "U+1F31F": "Zářící hvězda",
    "U+1F320": "Padající hvězda",
    "U+2601": "Mrak",
    "U+26C5": "Slunce za mrakem",
    "U+26C8": "Bouřkový mrak a déšť ≊ mrak s blesky a deštěm",
    "U+1F324": "Bílé slunce s malým mrakem ≊ slunce za malým mrakem",
    "U+1F325": "Bílé slunce za mrakem ≊ slunce za velkým mrakem",
    "U+1F326": "Bílé slunce za mrakem s deštěm ≊ slunce za mrakem s deštěm",
    "U+1F327": "Mrak s deštěm",
    "U+1F328": "Mrak se sněhem",
    "U+1F329": "Mrak s bleskem",
    "U+1F32A": "Mrak s tornádem ≊ tornádo",
    "U+1F32B": "Mlha",
    "U+1F32C": "Wind blowing face ≊ wind face",
    "U+1F300": "Cyklón",
    "U+1F308": "Duha",
    "U+1F302": "Uzavřený deštník",
    "U+2602": "Deštník",
    "U+2614": "Deštník s kapkami deště",
    "U+26F1": "Deštník na zemi",
    "U+26A1": "Znak vysokého napětí ≊ vysoké napětí",
    "U+2744": "Sněhová vločka",
    "U+2603": "Sněhulák",
    "U+26C4": "Sněhulák bez sněhu",
    "U+2604": "Kometa",
    "U+1F525": "Oheň",
    "U+1F4A7": "Kapička",
    "U+1F30A": "Vodní vlna",
    "U+1F383": "Jack-o-lantern",
    "U+1F384": "Vánoční strom",
    "U+1F386": "Ohňostroj",
    "U+1F387": "Ohňostroj prskavka ≊ prskavka",
    "U+2728": "Jiskří",
    "U+1F388": "Balón",
    "U+1F389": "Party popper",
    "U+1F38A": "Konfetová koule",
    "U+1F38B": "Strom tanabata",
    "U+1F38D": "Dekorace z borovice",
    "U+1F38E": "Japonské panenky",
    "U+1F38F": "Kaprový streamer",
    "U+1F390": "Zvonkohra větru",
    "U+1F391": "Obřad sledování měsíce ≊ obřad měsíce",
    "U+1F380": "Stuha",
    "U+1F381": "Zabalený dárek",
    "U+1F397": "Upomínková stuha",
    "U+1F39F": "Vstupenky",
    "U+1F3AB": "Lístek",
    "U+1F396": "Vojenská medaile",
    "U+1F3C6": "Trofej",
    "U+1F3C5": "Sportovní medaili",
    "U+1F947": "První místo medaile ≊ 1. místo medaile",
    "U+1F948": "Medaile za druhé místo ≊ medaile za 2. místo",
    "U+1F949": "Medaile za třetí místo ≊ medaile za 3. místo",
    "U+26BD": "Fotbalový míč",
    "U+26BE": "Baseball",
    "U+1F3C0": "Basketbal a obruč ≊ basketbal",
    "U+1F3D0": "Volejbal",
    "U+1F3C8": "Americký fotbal",
    "U+1F3C9": "Ragbyový fotbal",
    "U+1F3BE": "Tenisová raketa a míček ≊ tenis",
    "U+1F3B1": "Kulečník",
    "U+1F3B3": "Bowling",
    "U+1F3CF": "Kriketová pálka a míček ≊ kriket",
    "U+1F3D1": "Pozemní hokejka a míček ≊ pozemní hokej",
    "U+1F3D2": "Hokejka a puk",
    "U+1F3D3": "Stolní tenis pádlo a míček ≊ ping pong",
    "U+1F3F8": "Badmintonová raketa a míček ≊ badminton",
    "U+1F94A": "Boxerské rukavice",
    "U+1F94B": "Uniforma bojových umění",
    "U+1F945": "Branková síť",
    "U+1F3AF": "Přímý zásah",
    "U+26F3": "Vlajka v díře",
    "U+26F8": "Bruslit",
    "U+1F3A3": "Rybářský prut a ryba ≊ rybářský prut",
    "U+1F3BD": "Běžecká košile s vlečkou ≊ běžecká košile",
    "U+1F3BF": "Lyže a lyžařské boty ≊ lyže",
    "U+1F93A": "Šermíř ≊ oplocení osoby",
    "U+1F3C7": "Dostihy",
    "U+26F7": "Lyžař",
    "U+1F3C2": "Snowboardista",
    "U+1F3CC": "Golfista ≊ osoba hrající golf",
    "U+1F3C4": "Surfař ≊ osoba surfující",
    "U+1F3C4 U+1F3FB": "Osoba surfující, typ-1-2",
    "U+1F3C4 U+1F3FC": "Osoba surfující, typ 3",
    "U+1F3C4 U+1F3FD": "Osoba surfující, typ 4",
    "U+1F3C4 U+1F3FE": "Osoba surfující, typ-5",
    "U+1F3C4 U+1F3FF": "Osoba surfující, typ 6",
    "U+1F6A3": "Pramice ≊ osoba veslice",
    "U+1F6A3 U+1F3FB": "Osoba veslice, typ-1-2",
    "U+1F6A3 U+1F3FC": "Osoba veslice, typ-3",
    "U+1F6A3 U+1F3FD": "Osoba veslice, typ-4",
    "U+1F6A3 U+1F3FE": "Osoba veslice, typ-5",
    "U+1F6A3 U+1F3FF": "Osoba veslice, typ-6",
    "U+1F3CA": "Plavec ≊ osoba plavající",
    "U+1F3CA U+1F3FB": "Plavání osob, typ-1-2",
    "U+1F3CA U+1F3FC": "Plavání osoby, typ 3",
    "U+1F3CA U+1F3FD": "Plavání osoby, typ 4",
    "U+1F3CA U+1F3FE": "Plavání osoby, typ-5",
    "U+1F3CA U+1F3FF": "Plavání osoby, typ-6",
    "U+26F9": "Osoba s míčem",
    "U+26F9 U+1F3FB": "Osoba s míčem, typ-1-2",
    "U+26F9 U+1F3FC": "Osoba s míčem, typ 3",
    "U+26F9 U+1F3FD": "Osoba s míčem, typ 4",
    "U+26F9 U+1F3FE": "Osoba s míčem, typ 5",
    "U+26F9 U+1F3FF": "Osoba s míčem, typ-6",
    "U+1F3CB": "Vzpěrač ≊ vzpírání osoby",
    "U+1F3CB U+1F3FB": "Vzpírání osob, typ-1-2",
    "U+1F3CB U+1F3FC": "Vzpírání osob, typ 3",
    "U+1F3CB U+1F3FD": "Vzpírání osob, typ 4",
    "U+1F3CB U+1F3FE": "Vzpírání osob, typ-5",
    "U+1F3CB U+1F3FF": "Vzpírání osob, typ 6",
    "U+1F6B4": "Cyklista ≊ osoba na kole",
    "U+1F6B4 U+1F3FB": "Osoba na kole, typ-1-2",
    "U+1F6B4 U+1F3FC": "Osoba na kole, typ 3",
    "U+1F6B4 U+1F3FD": "Osoba na kole, typ 4",
    "U+1F6B4 U+1F3FE": "Osoba na kole, typ-5",
    "U+1F6B4 U+1F3FF": "Osoba na kole, typ 6",
    "U+1F6B5": "Horský cyklista ≊ osoba jízda na horském kole",
    "U+1F6B5 U+1F3FB": "Osoba na horském kole, typ-1-2",
    "U+1F6B5 U+1F3FC": "Osoba na horském kole, typ 3",
    "U+1F6B5 U+1F3FD": "Osoba na horském kole, typ 4",
    "U+1F6B5 U+1F3FE": "Osoba horská kola, typ 5",
    "U+1F6B5 U+1F3FF": "Osoba horská kola, typ 6",
    "U+1F938": "Osoba, která dělá přemet",
    "U+1F938 U+1F3FB": "Osoba, která dělá přemet, typ-1-2",
    "U+1F938 U+1F3FC": "Osoba, která dělá přemet, typ 3",
    "U+1F938 U+1F3FD": "Osoba, která dělá přemet, typ 4",
    "U+1F938 U+1F3FE": "Osoba, která dělá přemet, typ 5",
    "U+1F938 U+1F3FF": "Osoba, která dělá přemet, typ 6",
    "U+1F93C": "Zápasníci ≊ lidé zápasí",
    "U+1F93C U+1F3FB": "Lidový zápas, typ 1-2",
    "U+1F93C U+1F3FC": "Lidový zápas, typ 3",
    "U+1F93C U+1F3FD": "Lidový zápas, typ 4",
    "U+1F93C U+1F3FE": "Lidový zápas, typ 5",
    "U+1F93C U+1F3FF": "Lidový zápas, typ 6",
    "U+1F93D": "Vodní pólo ≊ osoba hrající vodní pólo",
    "U+1F93D U+1F3FB": "Osoba hrající vodní pólo, typ-1-2",
    "U+1F93D U+1F3FC": "Osoba hrající vodní pólo, typ 3",
    "U+1F93D U+1F3FD": "Osoba hrající vodní pólo, typ 4",
    "U+1F93D U+1F3FE": "Osoba hrající vodní pólo, typ 5",
    "U+1F93D U+1F3FF": "Osoba hrající vodní pólo, typ 6",
    "U+1F93E": "Házená ≊ osoba hrající házenou",
    "U+1F93E U+1F3FB": "Osoba hrající házenou, typ-1-2",
    "U+1F93E U+1F3FC": "Osoba hrající házenou, typ 3",
    "U+1F93E U+1F3FD": "Osoba hrající házenou, typ 4",
    "U+1F93E U+1F3FE": "Osoba hrající házenou, typ 5",
    "U+1F93E U+1F3FF": "Osoba hrající házenou, typ 6",
    "U+1F939": "Žonglování ≊ člověk žonglování",
    "U+1F939 U+1F3FB": "Osoba žonglování, typ-1-2",
    "U+1F939 U+1F3FC": "Osoba žonglování, typ 3",
    "U+1F939 U+1F3FD": "Osoba žonglování, typ 4",
    "U+1F939 U+1F3FE": "Osoba žonglování, typ 5",
    "U+1F939 U+1F3FF": "Osoba žonglování, typ 6",
    "U+1F3AE": "Videohra",
    "U+1F579": "Joystick",
    "U+1F3B2": "Hra zemřít",
    "U+2660": "Černý pikový oblek ≊ pikový oblek",
    "U+2665": "Černé srdce oblek ≊ srdce oblek",
    "U+2666": "Černý diamantový oblek ≊ diamantový oblek",
    "U+2663": "Černý klubový oblek ≊ klubový oblek",
    "U+1F0CF": "Hrací karta černý žolík ≊ žolík",
    "U+1F004": "Mahjong dlaždice červený drak ≊ mahjong červený drak",
    "U+1F3B4": "Květinové hrací karty",
    "U+1F507": "Reproduktor se zrušením zdvihu ≊ reproduktor vypnutý",
    "U+1F508": "Reproduktor",
    "U+1F509": "Reproduktor s jednou zvukovou vlnou ≊ zapnutý reproduktor",
    "U+1F50A": "Reproduktor se třemi zvukovými vlnami ≊ reproduktor nahlas",
    "U+1F4E2": "Reproduktor veřejného ozvučení ≊ reproduktor",
    "U+1F4E3": "Jásající megafon ≊ megafon",
    "U+1F4EF": "Poštovní roh",
    "U+1F514": "Zvonek",
    "U+1F515": "Zvonek se zrušením zdvihu ≊ zvonek s lomítkem",
    "U+1F3BC": "Hudební partitura",
    "U+1F3B5": "Hudební nota",
    "U+1F3B6": "Více not ≊ noty",
    "U+1F399": "Studiový mikrofon",
    "U+1F39A": "Jezdec úrovně",
    "U+1F39B": "Ovládací knoflíky",
    "U+1F3A4": "Mikrofon",
    "U+1F3A7": "Sluchátka",
    "U+1F4FB": "Rádio",
    "U+1F3B7": "Saxofon",
    "U+1F3B8": "Kytara",
    "U+1F3B9": "Hudební klávesnice",
    "U+1F3BA": "Trubka",
    "U+1F3BB": "Housle",
    "U+1F941": "Buben s paličkami ≊ buben",
    "U+1F4F1": "Mobilní telefon",
    "U+1F4F2": "Mobilní telefon se šipkou vpravo vlevo ≊ mobilní telefon se šipkou",
    "U+260E": "Černý telefon ≊ telefon",
    "U+1F4DE": "Telefonní sluchátko",
    "U+1F4DF": "Pager",
    "U+1F4E0": "Faxový přístroj",
    "U+1F50B": "Baterie",
    "U+1F50C": "Elektrická zástrčka",
    "U+1F4BB": "Osobní počítač ≊ přenosný počítač",
    "U+1F5A5": "Stolní počítač",
    "U+1F5A8": "Tiskárna",
    "U+2328": "Klávesnice",
    "U+1F5B1": "Třítlačítková myš ≊ počítačová myš",
    "U+1F5B2": "Trackball",
    "U+1F4BD": "Minidisk",
    "U+1F4BE": "Disketa",
    "U+1F4BF": "Optický disk",
    "U+1F4C0": "DVD",
    "U+1F3A5": "Filmová kamera",
    "U+1F39E": "Filmové rámečky",
    "U+1F4FD": "Filmový projektor",
    "U+1F3AC": "Klapka deska",
    "U+1F4FA": "Televize",
    "U+1F4F7": "Fotoaparát",
    "U+1F4F8": "Fotoaparát s bleskem",
    "U+1F4F9": "Videokamera",
    "U+1F4FC": "Videokazeta",
    "U+1F50D": "Lupa směřující doleva",
    "U+1F50E": "Lupa směřující doprava",
    "U+1F52C": "Mikroskop",
    "U+1F52D": "Dalekohled",
    "U+1F4E1": "Satelitní anténa",
    "U+1F56F": "Svíčka",
    "U+1F4A1": "Elektrická žárovka ≊ žárovka",
    "U+1F526": "Elektrická svítilna ≊ baterka",
    "U+1F3EE": "Lucerna izakaya ≊ červená papírová lucerna",
    "U+1F4D4": "Zápisník s ozdobným přebalem",
    "U+1F4D5": "Zavřená kniha",
    "U+1F4D6": "Otevřená kniha",
    "U+1F4D7": "Zelená kniha",
    "U+1F4D8": "Modrá kniha",
    "U+1F4D9": "Oranžová kniha",
    "U+1F4DA": "Knihy",
    "U+1F4D3": "Notebook",
    "U+1F4D2": "Účetní kniha",
    "U+1F4C3": "Stránka se zvlněním",
    "U+1F4DC": "Svitek",
    "U+1F4C4": "Strana nahoru",
    "U+1F4F0": "Noviny",
    "U+1F5DE": "Srolované noviny",
    "U+1F4D1": "Záložky záložek",
    "U+1F516": "Záložka",
    "U+1F3F7": "Označení",
    "U+1F4B0": "Pytel na peníze",
    "U+1F4B4": "Bankovka se znakem jenu ≊ jen bankovka",
    "U+1F4B5": "Bankovka se znakem dolaru ≊ dolarová bankovka",
    "U+1F4B6": "Bankovka se znakem euro ≊ eurobankovka",
    "U+1F4B7": "Bankovka se znakem libry ≊ librová bankovka",
    "U+1F4B8": "Peníze s křídly",
    "U+1F4B3": "Kreditní kartou",
    "U+1F4B9": "Graf s rostoucím trendem a znakem jenu ≊ graf rostoucí s jenem",
    "U+1F4B1": "Směnárna",
    "U+1F4B2": "Těžký znak dolaru",
    "U+2709": "Obálka",
    "U+1F4E7": "Symbol e-mailu ≊ e-mail",
    "U+1F4E8": "Příchozí obálka",
    "U+1F4E9": "Obálka se šipkou dolů nad ≊ obálka se šipkou",
    "U+1F4E4": "Přihrádka k odeslání",
    "U+1F4E5": "Zásobník doručené pošty",
    "U+1F4E6": "Balík",
    "U+1F4EB": "Uzavřená poštovní schránka se vztyčenou vlajkou",
    "U+1F4EA": "Zavřená poštovní schránka se sníženým praporkem",
    "U+1F4EC": "Otevřít poštovní schránku se vztyčenou vlajkou",
    "U+1F4ED": "Otevřít poštovní schránku se sníženým praporkem",
    "U+1F4EE": "Poštovní schránka",
    "U+1F5F3": "Volební urna s hlasovacím lístkem",
    "U+270F": "Tužka",
    "U+2712": "Černý hrot",
    "U+1F58B": "Vlevo dole plnicí pero ≊ plnicí pero",
    "U+1F58A": "Levé dolní kuličkové pero ≊ pero",
    "U+1F58C": "Štětec vlevo dole ≊ štětec",
    "U+1F58D": "Vlevo dole pastelka ≊ pastelka",
    "U+1F4DD": "Poznámka",
    "U+1F4BC": "Aktovka",
    "U+1F4C1": "Složku souboru",
    "U+1F4C2": "Otevřít složku se soubory",
    "U+1F5C2": "Oddělovače kartotéky",
    "U+1F4C5": "Kalendář",
    "U+1F4C6": "Trhací kalendář",
    "U+1F5D2": "Spirálový poznámkový blok ≊ spirálový poznámkový blok",
    "U+1F5D3": "Spirálová kalendářová podložka ≊ spirálový kalendář",
    "U+1F4C7": "Kartotéka",
    "U+1F4C8": "Graf se vzestupným trendem ≊ graf rostoucí",
    "U+1F4C9": "Graf s klesajícím trendem ≊ graf klesající",
    "U+1F4CA": "Sloupcový graf",
    "U+1F4CB": "Schránka",
    "U+1F4CC": "Připínáček",
    "U+1F4CD": "Kulatý připínáček",
    "U+1F4CE": "Kancelářská sponka",
    "U+1F587": "Spojené kancelářské sponky",
    "U+1F4CF": "Rovné pravítko",
    "U+1F4D0": "Trojúhelníkové pravítko",
    "U+2702": "Černé nůžky ≊ nůžky",
    "U+1F5C3": "Kartotéka",
    "U+1F5C4": "Kartotéka",
    "U+1F5D1": "Odpadkový koš",
    "U+1F512": "Zámek",
    "U+1F513": "Otevřený zámek",
    "U+1F50F": "Zámek s inkoustovým perem ≊ zámek s perem",
    "U+1F510": "Uzavřený zámek s klíčem",
    "U+1F511": "Klíč",
    "U+1F5DD": "Starý klíč",
    "U+1F528": "Kladivo",
    "U+26CF": "Výběr",
    "U+2692": "Kladivo a krumpáč",
    "U+1F6E0": "Kladivo a klíč",
    "U+1F5E1": "Dýka nůž ≊ dýka",
    "U+2694": "Zkřížené meče",
    "U+1F52B": "Pistole",
    "U+1F3F9": "Luk a šíp",
    "U+1F6E1": "Štít",
    "U+1F527": "Klíč",
    "U+1F529": "Matice a šroubu",
    "U+2699": "Zařízení",
    "U+1F5DC": "Komprese",
    "U+2697": "Alembic",
    "U+2696": "Váhy ≊ balanční váha",
    "U+1F517": "Symbol odkazu ≊ odkaz",
    "U+26D3": "Řetězy",
    "U+1F489": "Stříkačka",
    "U+1F48A": "Pilulka",
    "U+1F6AC": "Symbol kouření ≊ kouření",
    "U+26B0": "Rakev",
    "U+26B1": "Pohřební urna",
    "U+1F5FF": "Moyai ≊ moai",
    "U+1F6E2": "Olejový buben",
    "U+1F52E": "Křišťálovou kouli",
    "U+1F6D2": "Nákupní vozík ≊ nákupní košík",
    "U+1F3E7": "Bankomat ≊ bankomat",
    "U+1F6AE": "Dát podestýlku na její místo symbol ≊ podestýlka do popelnice znak",
    "U+1F6B0": "Symbol pitné vody ≊ pitná voda",
    "U+267F": "Symbol invalidního vozíku ≊ invalidní vozík",
    "U+1F6B9": "Pánský symbol ≊ pánský pokoj",
    "U+1F6BA": "Dámský symbol ≊ dámský pokoj",
    "U+1F6BB": "Toaleta",
    "U+1F6BC": "Symbol dítěte",
    "U+1F6BE": "Klozet",
    "U+1F6C2": "Pasová kontrola",
    "U+1F6C3": "Zvyky",
    "U+1F6C4": "Výdej zavazadel",
    "U+1F6C5": "Odložené zavazadlo",
    "U+26A0": "Výstražná značka ≊ varování",
    "U+1F6B8": "Přechod dětí",
    "U+26D4": "VSTUP ZAKÁZÁN",
    "U+1F6AB": "Značka zákaz vjezdu ≊ zákaz",
    "U+1F6B3": "Žádná kola",
    "U+1F6AD": "Symbol zákazu kouření ≊ zákaz kouření",
    "U+1F6AF": "Symbol nevyhazovat ≊ žádné odpadky",
    "U+1F6B1": "Symbol nepitné vody ≊ nepitná voda",
    "U+1F6B7": "Žádní chodci",
    "U+1F4F5": "Žádné mobilní telefony",
    "U+1F51E": "Nikdo mladší osmnácti let symbol ≊ nikdo mladší osmnácti let",
    "U+2622": "Radioaktivní znak ≊ radioaktivní",
    "U+2623": "Biohazard znak ≊ biohazard",
    "U+2B06": "Nahoru černá šipka ≊ šipka nahoru",
    "U+2197": "Severovýchodní šipka ≊ šipka nahoru-doprava",
    "U+27A1": "Černá šipka vpravo ≊ šipka vpravo",
    "U+2198": "Jihovýchodní šipka ≊ šipka vpravo dolů",
    "U+2B07": "Dolů černá šipka ≊ šipka dolů",
    "U+2199": "Jihozápadní šipka ≊ šipka doleva dolů",
    "U+2B05": "Doleva černá šipka ≊ šipka doleva",
    "U+2196": "Severozápadní šipka ≊ šipka doleva",
    "U+2195": "Šipka nahoru dolů ≊ šipka nahoru-dolů",
    "U+2194": "Šipka doleva doprava ≊ šipka doleva-doprava",
    "U+21A9": "Šipka doleva s háčkem ≊ šipka doprava zakřivená doleva",
    "U+21AA": "Šipka vpravo s háčkem ≊ šipka vlevo zakřivená vpravo",
    "U+2934": "Šipka směřující doprava a poté zakřivená nahoru ≊ šipka doprava zakřivená nahoru",
    "U+2935": "Šipka směřující doprava a poté zakřivená dolů ≊ šipka doprava zakřivená dolů",
    "U+1F503": "Ve směru hodinových ručiček dolů a nahoru šipky otevřeného kruhu ≊ svislé šipky ve směru hodinových ručiček",
    "U+1F504": "Proti směru hodinových ručiček dolů a nahoru šipky otevřeného kruhu ≊ tlačítko šipky proti směru hodinových ručiček",
    "U+1F519": "Zpět se šipkou doleva nad ≊ šipkou zpět",
    "U+1F51A": "Konec šipkou doleva nad ≊ šipkou konce",
    "U+1F51B": "Zapnuto s vykřičníkem se šipkou doleva doprava nad ≊ zapnuto! šipka",
    "U+1F51C": "Brzy se šipkou doprava nad ≊ brzy šipkou",
    "U+1F51D": "Nahoře se šipkou nahoru nad ≊ horní šipkou",
    "U+1F6D0": "Místo uctívání",
    "U+269B": "Symbol atomu",
    "U+1F549": "Symbol om ≊ om",
    "U+2721": "Davidova hvězda",
    "U+2638": "Kolo dharmy",
    "U+262F": "Jin jang",
    "U+271D": "Latinský kříž",
    "U+2626": "Pravoslavný kříž",
    "U+262A": "Hvězda a půlměsíc",
    "U+262E": "Symbol míru",
    "U+1F54E": "Menora s devíti větvemi ≊ menora",
    "U+1F52F": "Šesticípá hvězda se střední tečkou ≊ tečkovaná šesticípá hvězda",
    "U+267B": "Černý univerzální symbol recyklace ≊ symbol recyklace",
    "U+1F4DB": "Jmenovka",
    "U+269C": "Fleur-de-lis",
    "U+1F530": "Japonský symbol pro začátečníky",
    "U+1F531": "Znak trojzubec",
    "U+2B55": "Těžký velký kruh",
    "U+2705": "Bílé těžké zaškrtnutí",
    "U+2611": "Volební urna s šekem",
    "U+2714": "Silné zaškrtnutí",
    "U+2716": "Těžké násobení x",
    "U+274C": "Křížová značka",
    "U+274E": "Záporná čtvercová křížová značka ≊ křížová značka",
    "U+2795": "Těžké znaménko plus",
    "U+2796": "Těžké znaménko mínus",
    "U+2797": "Znamení těžké divize",
    "U+27B0": "Kudrnatá smyčka",
    "U+27BF": "Dvojitá kudrnatá smyčka",
    "U+303D": "Část střídavá značka",
    "U+2733": "Osmipaprsková hvězdička ≊ osmipaprsková hvězdička",
    "U+2734": "Osmicípá černá hvězda ≊ osmicípá hvězda",
    "U+2747": "Jiskra",
    "U+203C": "Dvojitý vykřičník",
    "U+2049": "Vykřičník otazník",
    "U+2753": "Černý otazník ornament ≊ otazník",
    "U+2754": "Bílý otazník ornament ≊ bílý otazník",
    "U+2755": "Bílý vykřičník ornament ≊ bílý vykřičník",
    "U+2757": "Symbol těžkého vykřičníku ≊ vykřičník",
    "U+3030": "Zvlněná čárka",
    "U+00A9": "Autorská značka ≊ copyright",
    "U+00AE": "Registrovaný znak ≊ registrovaný",
    "U+2122": "Ochranná známka označení ≊ ochranná známka",
    "U+2648": "Beran",
    "U+2649": "Býk",
    "U+264A": "Blíženci",
    "U+264B": "Rakovina",
    "U+264C": "Lev",
    "U+264D": "Panna",
    "U+264E": "Váhy",
    "U+264F": "Štír",
    "U+2650": "Střelec",
    "U+2651": "Kozoroh",
    "U+2652": "Vodnář",
    "U+2653": "Ryby",
    "U+26CE": "Ophiuchus",
    "U+1F500": "Kroucené šipky doprava ≊ tlačítko pro náhodné přehrávání skladeb",
    "U+1F501": "Ve směru hodinových ručiček doprava a doleva šipky otevřeného kruhu ≊ tlačítko opakování",
    "U+1F502": "Ve směru hodinových ručiček doprava a doleva šipky otevřeného kruhu se zakroužkovaným jedním překrytím ≊ opakujte jedno tlačítko",
    "U+25B6": "Černý trojúhelník směřující doprava ≊ tlačítko přehrávání",
    "U+23E9": "Černý dvojitý trojúhelník směřující doprava ≊ tlačítko rychlého převíjení",
    "U+23ED": "Černý dvojitý trojúhelník směřující doprava se svislým pruhem ≊ tlačítko další stopy",
    "U+23EF": "Černý trojúhelník směřující doprava s dvojitým svislým pruhem ≊ tlačítko přehrávání nebo pauzy",
    "U+25C0": "Černý trojúhelník směřující doleva ≊ tlačítko zpětného chodu",
    "U+23EA": "Černý dvojitý trojúhelník směřující doleva ≊ tlačítko rychlého vzad",
    "U+23EE": "Černý dvojitý trojúhelník směřující doleva se svislým pruhem ≊ tlačítko poslední stopy",
    "U+1F53C": "Nahoru směřující malý červený trojúhelník ≊ tlačítko nahoru",
    "U+23EB": "Černý dvojitý trojúhelník směřující nahoru ≊ tlačítko rychlého nahoru",
    "U+1F53D": "Malý červený trojúhelník směřující dolů ≊ tlačítko dolů",
    "U+23EC": "Černý dvojitý trojúhelník směřující dolů ≊ tlačítko rychlého dolů",
    "U+23F8": "Dvojitý svislý pruh ≊ tlačítko pauzy",
    "U+23F9": "Černý čtverec pro stop ≊ tlačítko stop",
    "U+23FA": "Černý kruh pro záznam ≊ tlačítko záznamu",
    "U+23CF": "Symbol vysunutí ≊ tlačítko vysunutí",
    "U+1F3A6": "Kino",
    "U+1F505": "Symbol nízkého jasu ≊ ztlumené tlačítko",
    "U+1F506": "Symbol vysokého jasu ≊ jasné tlačítko",
    "U+1F4F6": "Anténa s tyčemi ≊ tyče antény",
    "U+1F4F3": "Vibrační režim",
    "U+1F4F4": "Mobilní telefon vypnutý",
    "U+0023 U+FE0F U+20E3": "Klíčenka #",
    "U+002A U+FE0F U+20E3": "Klíčenka *",
    "U+0030 U+FE0F U+20E3": "Klíčenka 0",
    "U+0031 U+FE0F U+20E3": "Klíčenka 1",
    "U+0032 U+FE0F U+20E3": "Klíčenka 2",
    "U+0033 U+FE0F U+20E3": "Klíčenka 3",
    "U+0034 U+FE0F U+20E3": "Klíčenka 4",
    "U+0035 U+FE0F U+20E3": "Klíčenka 5",
    "U+0036 U+FE0F U+20E3": "Klíčenka 6",
    "U+0037 U+FE0F U+20E3": "Klíčenka 7",
    "U+0038 U+FE0F U+20E3": "Klíčenka 8",
    "U+0039 U+FE0F U+20E3": "Klíčenka 9",
    "U+1F51F": "Keycap ten ≊ keycap #",
    "U+1F4AF": "Symbol sto bodů ≊ sto bodů",
    "U+1F520": "Vstupní symbol pro velká písmena latinky ≊ zadávání velkých písmen latinky",
    "U+1F521": "Vstupní symbol pro malá písmena latinky ≊ zadání malých písmen latinky",
    "U+1F522": "Vstupní symbol pro čísla ≊ vstupní čísla",
    "U+1F523": "Vstupní symbol pro symboly ≊ vstupní symboly",
    "U+1F524": "Vstupní symbol pro latinská písmena ≊ zadávání latinských písmen",
    "U+1F170": "Záporné druhé velké latinské písmeno a ≊ a tlačítko",
    "U+1F18E": "Záporné druhé mocnině tlačítko ab ≊ ab",
    "U+1F171": "Záporné druhé velké latinské písmeno b ≊ b tlačítko",
    "U+1F191": "Čtvercový tř",
    "U+1F192": "Čtverečkovaný v pohodě",
    "U+1F193": "Čtverec zdarma",
    "U+2139": "Informační zdroj",
    "U+1F194": "Čtvercové id",
    "U+24C2": "Velké písmeno latinky v kroužku m ≊ písmeno m v kroužku",
    "U+1F195": "Čtverečkovaný nový",
    "U+1F196": "Na druhou ng",
    "U+1F17E": "Záporné druhé velké latinské písmeno o ≊ o tlačítko",
    "U+1F197": "Na druhou v pořádku",
    "U+1F17F": "Záporné druhé velké latinské písmeno p ≊ tlačítko p",
    "U+1F198": "Čtvercový sos",
    "U+1F199": "Čtvereček nahoru s vykřičníkem ≊ nahoru! tlačítko",
    "U+1F19A": "Čtvercový vs",
    "U+1F201": "Čtvercová katakana koko",
    "U+1F202": "Čtvercová katakana sa",
    "U+1F237": "Squared cjk unified ideograph-6708 ≊ čtvercový ideograph měsíce",
    "U+1F236": "Squared cjk unified ideograph-6709 ≊ squared exist ideograph",
    "U+1F22F": "Squared cjk unified ideograph-6307 ≊ ideograph čtvercového prstu",
    "U+1F250": "Zakroužkovaný ideograph výhoda ≊ zakroužkovaná výhoda ideograph",
    "U+1F239": "Squared cjk unified ideograph-5272 ≊ squared division ideograph",
    "U+1F21A": "Squared cjk unified ideograph-7121 ≊ squared negation ideograph",
    "U+1F232": "Squared cjk unified ideograph-7981 ≊ squared prohibit ideograph",
    "U+1F251": "Zakroužkovaný ideograph akceptovat ≊ zakroužkovaný akceptovat ideograph",
    "U+1F238": "Squared cjk unified ideograph-7533 ≊ squared Apply ideograph",
    "U+1F234": "Squared cjk unified ideograph-5408 ≊ squared together ideograph",
    "U+1F233": "Squared cjk unified ideograph-7a7a ≊ squared empty ideograph",
    "U+3297": "Zakroužkovaný ideograph blahopřání ≊ zakroužkovaný blahopřání ideograf",
    "U+3299": "Kroužkovaný ideograph secret ≊ kroužkovaný tajný ideograph",
    "U+1F23A": "Squared cjk unified ideograph-55b6 ≊ čtvercový operační ideograph",
    "U+1F235": "Squared cjk unified ideograph-6e80 ≊ squared fullness ideograph",
    "U+25AA": "Černý malý čtverec",
    "U+25AB": "Bílý malý čtverec",
    "U+25FB": "Bílý střední čtverec",
    "U+25FC": "Černý střední čtverec",
    "U+25FD": "Bílý střední malý čtverec ≊ bílý středně malý čtverec",
    "U+25FE": "Černý střední malý čtverec ≊ černý středně malý čtverec",
    "U+2B1B": "Černý velký čtverec",
    "U+2B1C": "Bílý velký čtverec",
    "U+1F536": "Velký oranžový diamant",
    "U+1F537": "Velký modrý diamant",
    "U+1F538": "Malý oranžový diamant",
    "U+1F539": "Malý modrý diamant",
    "U+1F53A": "Červený trojúhelník směřující nahoru ≊ červený trojúhelník směřující nahoru",
    "U+1F53B": "Červený trojúhelník směřující dolů ≊ červený trojúhelník směřující dolů",
    "U+1F4A0": "Kosočtverec s tečkou uvnitř ≊ kosočtverec s tečkou",
    "U+1F518": "Přepínač",
    "U+1F532": "Černé čtvercové tlačítko",
    "U+1F533": "Bílé čtvercové tlačítko",
    "U+26AA": "Střední bílý kruh ≊ bílý kruh",
    "U+26AB": "Střední černý kruh ≊ černý kruh",
    "U+1F534": "Velký červený kruh ≊ červený kruh",
    "U+1F535": "Velký modrý kruh ≊ modrý kruh",
    "U+1F3C1": "Šachovnicová vlajka",
    "U+1F6A9": "Trojúhelníková vlajka na sloupku ≊ trojúhelníková vlajka",
    "U+1F38C": "Zkřížené vlajky",
    "U+1F3F4": "Mávání černou vlajkou",
    "U+1F3F3": "Mávání bílou vlajkou",
    "U+1F1E6 U+1F1E8": "Ostrov ascension",
    "U+1F1E6 U+1F1E9": "Andorra",
    "U+1F1E6 U+1F1EA": "Spojené arabské emiráty",
    "U+1F1E6 U+1F1EB": "Afghánistán",
    "U+1F1E6 U+1F1EC": "Antigua",
    "U+1F1E6 U+1F1EE": "Anguilla",
    "U+1F1E6 U+1F1F1": "Albánie",
    "U+1F1E6 U+1F1F2": "Arménie",
    "U+1F1E6 U+1F1F4": "Angola",
    "U+1F1E6 U+1F1F6": "Antarktida",
    "U+1F1E6 U+1F1F7": "Argentina",
    "U+1F1E6 U+1F1F8": "Americká samoa",
    "U+1F1E6 U+1F1F9": "Rakousko",
    "U+1F1E6 U+1F1FA": "Austrálie",
    "U+1F1E6 U+1F1FC": "Aruba",
    "U+1F1E6 U+1F1FD": "Ã…pevninské ostrovy",
    "U+1F1E6 U+1F1FF": "Ázerbajdžán",
    "U+1F1E7 U+1F1E6": "Bosna",
    "U+1F1E7 U+1F1E7": "Barbados",
    "U+1F1E7 U+1F1E9": "Bangladéš",
    "U+1F1E7 U+1F1EA": "Belgie",
    "U+1F1E7 U+1F1EB": "Burkina faso",
    "U+1F1E7 U+1F1EC": "Bulharsko",
    "U+1F1E7 U+1F1ED": "Bahrajnu",
    "U+1F1E7 U+1F1EE": "Burundi",
    "U+1F1E7 U+1F1EF": "Benin",
    "U+1F1E7 U+1F1F1": "Ulice. barthã©lemy",
    "U+1F1E7 U+1F1F2": "Bermudy",
    "U+1F1E7 U+1F1F3": "Brunej",
    "U+1F1E7 U+1F1F4": "Bolívie",
    "U+1F1E7 U+1F1F6": "Karibské nizozemsko",
    "U+1F1E7 U+1F1F7": "Brazílie",
    "U+1F1E7 U+1F1F8": "Bahamy",
    "U+1F1E7 U+1F1F9": "Bhútán",
    "U+1F1E7 U+1F1FB": "Bouvetův ostrov",
    "U+1F1E7 U+1F1FC": "Botswana",
    "U+1F1E7 U+1F1FE": "Bělorusko",
    "U+1F1E7 U+1F1FF": "Belize",
    "U+1F1E8 U+1F1E6": "Kanada",
    "U+1F1E8 U+1F1E8": "Kokosové (kýlové) ostrovy",
    "U+1F1E8 U+1F1E9": "Kongo - Kinshasa",
    "U+1F1E8 U+1F1EB": "Středoafrická republika",
    "U+1F1E8 U+1F1EC": "Kongo - Brazzaville",
    "U+1F1E8 U+1F1ED": "Švýcarsko",
    "U+1F1E8 U+1F1EE": "Cã'te dâ€™ivoire",
    "U+1F1E8 U+1F1F0": "Kuchařské ostrovy",
    "U+1F1E8 U+1F1F1": "Chile",
    "U+1F1E8 U+1F1F2": "Kamerun",
    "U+1F1E8 U+1F1F3": "Čína",
    "U+1F1E8 U+1F1F4": "Kolumbie",
    "U+1F1E8 U+1F1F5": "Ostrov clipperton",
    "U+1F1E8 U+1F1F7": "Kostarika",
    "U+1F1E8 U+1F1FA": "Kuba",
    "U+1F1E8 U+1F1FB": "Kapverdy",
    "U+1F1E8 U+1F1FC": "Curaã§ao",
    "U+1F1E8 U+1F1FD": "Vánoční ostrov",
    "U+1F1E8 U+1F1FE": "Kypr",
    "U+1F1E8 U+1F1FF": "Česká republika",
    "U+1F1E9 U+1F1EA": "Německo",
    "U+1F1E9 U+1F1EC": "Diego garcia",
    "U+1F1E9 U+1F1EF": "Džibutsko",
    "U+1F1E9 U+1F1F0": "Dánsko",
    "U+1F1E9 U+1F1F2": "Dominika",
    "U+1F1E9 U+1F1F4": "Dominikánská republika",
    "U+1F1E9 U+1F1FF": "Alžírsko",
    "U+1F1EA U+1F1E6": "Ceuta",
    "U+1F1EA U+1F1E8": "Ekvádor",
    "U+1F1EA U+1F1EA": "Estonsko",
    "U+1F1EA U+1F1EC": "Egypt",
    "U+1F1EA U+1F1ED": "Západní sahara",
    "U+1F1EA U+1F1F7": "Eritrea",
    "U+1F1EA U+1F1F8": "Španělsko",
    "U+1F1EA U+1F1F9": "Etiopie",
    "U+1F1EA U+1F1FA": "Evropská unie",
    "U+1F1EB U+1F1EE": "Finsko",
    "U+1F1EB U+1F1EF": "Fidži",
    "U+1F1EB U+1F1F0": "Falklandské ostrovy",
    "U+1F1EB U+1F1F2": "Mikronésie",
    "U+1F1EB U+1F1F4": "Faerské ostrovy",
    "U+1F1EB U+1F1F7": "Francie",
    "U+1F1EC U+1F1E6": "Gabon",
    "U+1F1EC U+1F1E7": "Spojené království",
    "U+1F1EC U+1F1E9": "Grenada",
    "U+1F1EC U+1F1EA": "Gruzie",
    "U+1F1EC U+1F1EB": "Francouzská Guayana",
    "U+1F1EC U+1F1EC": "Guernsey",
    "U+1F1EC U+1F1ED": "Ghana",
    "U+1F1EC U+1F1EE": "Gibraltar",
    "U+1F1EC U+1F1F1": "Grónsko",
    "U+1F1EC U+1F1F2": "Gambie",
    "U+1F1EC U+1F1F3": "Guinea",
    "U+1F1EC U+1F1F5": "Guadeloupe",
    "U+1F1EC U+1F1F6": "Rovníková guinea",
    "U+1F1EC U+1F1F7": "Řecko",
    "U+1F1EC U+1F1F8": "Jižní Gruzie",
    "U+1F1EC U+1F1F9": "Guatemala",
    "U+1F1EC U+1F1FA": "Guam",
    "U+1F1EC U+1F1FC": "Guinea-bissau",
    "U+1F1EC U+1F1FE": "Guyana",
    "U+1F1ED U+1F1F0": "Hongkongská sar čína",
    "U+1F1ED U+1F1F2": "Slyšeli",
    "U+1F1ED U+1F1F3": "Honduras",
    "U+1F1ED U+1F1F7": "Chorvatsko",
    "U+1F1ED U+1F1F9": "Haiti",
    "U+1F1ED U+1F1FA": "Maďarsko",
    "U+1F1EE U+1F1E8": "Kanárské ostrovy",
    "U+1F1EE U+1F1E9": "Indonésie",
    "U+1F1EE U+1F1EA": "Irsko",
    "U+1F1EE U+1F1F1": "Izrael",
    "U+1F1EE U+1F1F2": "Ostrov člověka",
    "U+1F1EE U+1F1F3": "Indie",
    "U+1F1EE U+1F1F4": "Území britského Indického oceánu",
    "U+1F1EE U+1F1F6": "Irák",
    "U+1F1EE U+1F1F7": "Írán",
    "U+1F1EE U+1F1F8": "Island",
    "U+1F1EE U+1F1F9": "Itálie",
    "U+1F1EF U+1F1EA": "Trikot",
    "U+1F1EF U+1F1F2": "Jamaica",
    "U+1F1EF U+1F1F4": "Jordán",
    "U+1F1EF U+1F1F5": "Japonsko",
    "U+1F1F0 U+1F1EA": "Keňa",
    "U+1F1F0 U+1F1EC": "Kyrgyzstánu",
    "U+1F1F0 U+1F1ED": "Kambodža",
    "U+1F1F0 U+1F1EE": "Kiribati",
    "U+1F1F0 U+1F1F2": "Komory",
    "U+1F1F0 U+1F1F3": "Ulice. kitts",
    "U+1F1F0 U+1F1F5": "Severní korea",
    "U+1F1F0 U+1F1F7": "Jižní korea",
    "U+1F1F0 U+1F1FC": "Kuvajt",
    "U+1F1F0 U+1F1FE": "Kajmanské ostrovy",
    "U+1F1F0 U+1F1FF": "Kazachstán",
    "U+1F1F1 U+1F1E6": "Laos",
    "U+1F1F1 U+1F1E7": "Libanon",
    "U+1F1F1 U+1F1E8": "Ulice. lucia",
    "U+1F1F1 U+1F1EE": "Lichtenštejnsko",
    "U+1F1F1 U+1F1F0": "Srí lanka",
    "U+1F1F1 U+1F1F7": "Libérie",
    "U+1F1F1 U+1F1F8": "Lesotho",
    "U+1F1F1 U+1F1F9": "Litva",
    "U+1F1F1 U+1F1FA": "Lucembursko",
    "U+1F1F1 U+1F1FB": "Lotyšsko",
    "U+1F1F1 U+1F1FE": "Libye",
    "U+1F1F2 U+1F1E6": "Maroko",
    "U+1F1F2 U+1F1E8": "Monako",
    "U+1F1F2 U+1F1E9": "Moldavsko",
    "U+1F1F2 U+1F1EA": "Černá Hora",
    "U+1F1F2 U+1F1EB": "Ulice. Martin",
    "U+1F1F2 U+1F1EC": "Madagaskar",
    "U+1F1F2 U+1F1ED": "Marshallovy ostrovy",
    "U+1F1F2 U+1F1F0": "Makedonie",
    "U+1F1F2 U+1F1F1": "Mali",
    "U+1F1F2 U+1F1F2": "Myanmar (barma)",
    "U+1F1F2 U+1F1F3": "Mongolsko",
    "U+1F1F2 U+1F1F4": "Macau sar čína",
    "U+1F1F2 U+1F1F5": "Ostrovy severní Mariany",
    "U+1F1F2 U+1F1F6": "Martinique",
    "U+1F1F2 U+1F1F7": "Mauritánie",
    "U+1F1F2 U+1F1F8": "Montserrat",
    "U+1F1F2 U+1F1F9": "Malta",
    "U+1F1F2 U+1F1FA": "Mauricius",
    "U+1F1F2 U+1F1FB": "Maledivy",
    "U+1F1F2 U+1F1FC": "Malawi",
    "U+1F1F2 U+1F1FD": "Mexiko",
    "U+1F1F2 U+1F1FE": "Malajsie",
    "U+1F1F2 U+1F1FF": "Mosambik",
    "U+1F1F3 U+1F1E6": "Namibie",
    "U+1F1F3 U+1F1E8": "Nová Kaledonie",
    "U+1F1F3 U+1F1EA": "Niger",
    "U+1F1F3 U+1F1EB": "Ostrov norfolk",
    "U+1F1F3 U+1F1EC": "Nigérie",
    "U+1F1F3 U+1F1EE": "Nikaragua",
    "U+1F1F3 U+1F1F1": "Nizozemí",
    "U+1F1F3 U+1F1F4": "Norsko",
    "U+1F1F3 U+1F1F5": "Nepál",
    "U+1F1F3 U+1F1F7": "Nauru",
    "U+1F1F3 U+1F1FA": "Niue",
    "U+1F1F3 U+1F1FF": "Nový Zéland",
    "U+1F1F4 U+1F1F2": "Omán",
    "U+1F1F5 U+1F1E6": "Panama",
    "U+1F1F5 U+1F1EA": "Peru",
    "U+1F1F5 U+1F1EB": "Francouzská Polynésie",
    "U+1F1F5 U+1F1EC": "Papua-nová guinea",
    "U+1F1F5 U+1F1ED": "Filipíny",
    "U+1F1F5 U+1F1F0": "Pákistán",
    "U+1F1F5 U+1F1F1": "Polsko",
    "U+1F1F5 U+1F1F2": "Ulice. pierre",
    "U+1F1F5 U+1F1F3": "Pitcairnovy ostrovy",
    "U+1F1F5 U+1F1F7": "Portoriko",
    "U+1F1F5 U+1F1F8": "Palestinská území",
    "U+1F1F5 U+1F1F9": "Portugalsko",
    "U+1F1F5 U+1F1FC": "Palau",
    "U+1F1F5 U+1F1FE": "Paraguay",
    "U+1F1F6 U+1F1E6": "Katar",
    "U+1F1F7 U+1F1EA": "Rã© unie",
    "U+1F1F7 U+1F1F4": "Rumunsko",
    "U+1F1F7 U+1F1F8": "Srbsko",
    "U+1F1F7 U+1F1FA": "Rusko",
    "U+1F1F7 U+1F1FC": "Rwandě",
    "U+1F1F8 U+1F1E6": "Saúdská Arábie",
    "U+1F1F8 U+1F1E7": "Šalamounovy ostrovy",
    "U+1F1F8 U+1F1E8": "Seychely",
    "U+1F1F8 U+1F1E9": "Súdán",
    "U+1F1F8 U+1F1EA": "Švédsko",
    "U+1F1F8 U+1F1EC": "Singapur",
    "U+1F1F8 U+1F1ED": "Ulice. helena",
    "U+1F1F8 U+1F1EE": "Slovinsko",
    "U+1F1F8 U+1F1EF": "Svalbard",
    "U+1F1F8 U+1F1F0": "Slovensko",
    "U+1F1F8 U+1F1F1": "Sierra leone",
    "U+1F1F8 U+1F1F2": "San marino",
    "U+1F1F8 U+1F1F3": "Senegal",
    "U+1F1F8 U+1F1F4": "Somálsko",
    "U+1F1F8 U+1F1F7": "Surinam",
    "U+1F1F8 U+1F1F8": "Jižní súdán",
    "U+1F1F8 U+1F1F9": "Sã£o tomã©",
    "U+1F1F8 U+1F1FB": "El salvador",
    "U+1F1F8 U+1F1FD": "Sint maarten",
    "U+1F1F8 U+1F1FE": "Sýrie",
    "U+1F1F8 U+1F1FF": "Svazijsko",
    "U+1F1F9 U+1F1E6": "Tristan da cunha",
    "U+1F1F9 U+1F1E8": "Turci",
    "U+1F1F9 U+1F1E9": "Čad",
    "U+1F1F9 U+1F1EB": "Francouzská jižní území",
    "U+1F1F9 U+1F1EC": "Togo",
    "U+1F1F9 U+1F1ED": "Thajsko",
    "U+1F1F9 U+1F1EF": "Tádžikistán",
    "U+1F1F9 U+1F1F0": "Tokelau",
    "U+1F1F9 U+1F1F1": "Východní Timor",
    "U+1F1F9 U+1F1F2": "Turkmenistán",
    "U+1F1F9 U+1F1F3": "Tunisko",
    "U+1F1F9 U+1F1F4": "Tonga",
    "U+1F1F9 U+1F1F7": "Turecko",
    "U+1F1F9 U+1F1F9": "Trinidad",
    "U+1F1F9 U+1F1FB": "Tuvalu",
    "U+1F1F9 U+1F1FC": "Tchaj-wan",
    "U+1F1F9 U+1F1FF": "Tanzanie",
    "U+1F1FA U+1F1E6": "Ukrajina",
    "U+1F1FA U+1F1EC": "Uganda",
    "U+1F1FA U+1F1F2": "Nás. odlehlé ostrovy",
    "U+1F1FA U+1F1F8": "Spojené státy",
    "U+1F1FA U+1F1FE": "Uruguay",
    "U+1F1FA U+1F1FF": "Uzbekistán",
    "U+1F1FB U+1F1E6": "Vatikán",
    "U+1F1FB U+1F1E8": "Ulice. vincent",
    "U+1F1FB U+1F1EA": "Venezuela",
    "U+1F1FB U+1F1EC": "Britské panenské ostrovy",
    "U+1F1FB U+1F1EE": "Nás. Panenské ostrovy",
    "U+1F1FB U+1F1F3": "Vietnam",
    "U+1F1FB U+1F1FA": "Vanuatu",
    "U+1F1FC U+1F1EB": "Wallis",
    "U+1F1FC U+1F1F8": "Samoa",
    "U+1F1FD U+1F1F0": "Kosovo",
    "U+1F1FE U+1F1EA": "Jemen",
    "U+1F1FE U+1F1F9": "Majotka",
    "U+1F1FF U+1F1E6": "Jižní afriku",
    "U+1F1FF U+1F1F2": "Zambie",
    "U+1F1FF U+1F1FC": "Zimbabwee"
};
