import { czech } from "./translations";
import { CharInfo, EmojiData } from "./types";

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const emojiDataListToCharInfoList = (emojiDataList: EmojiData[]): CharInfo[] => emojiDataList.map((emojiData) => {
    const mergedUnicodes = emojiData.unicode.join(" ")
    const czechTranslation = czech[mergedUnicodes];
    if (czechTranslation === undefined) {
        console.log(`${mergedUnicodes}: Znak bez překladu`);
    }
    const codes = emojiData.unicode
        .map((codePoint) => codePoint.substring(2))
        .map((hex) => parseInt(`0x${hex}`, 16))
    const char = String.fromCodePoint(...codes)

    return {
        id: mergedUnicodes,
        char: char,
        title: capitalize(`Unicode: ${mergedUnicodes}\nHTML kód: ${emojiData.htmlCode.join(" ")}\nNázev: ${emojiData.name} (${czechTranslation?.toLowerCase() ?? "bez překladu"})\n`),
        top: -0.05,
    };
});

export const filterEmoji = (
    emojiList: CharInfo[] | undefined,
    query: string | undefined,
): CharInfo[] | undefined => {
    if (emojiList === undefined) {
        return undefined;
    }

    if (emojiList.length === 0) {
        return [];
    }

    const idList = emojiList.map(charInfo => charInfo.id);

    const uniqueEmojiList = emojiList.filter((charInfo, index) => idList.indexOf(charInfo.id) === index)

    if (query === undefined) {
        return uniqueEmojiList;
    }

    return uniqueEmojiList.filter(charInfo => charInfo.title.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
}
